import React, { CSSProperties, ReactElement } from 'react'
import { HiChevronDown, HiOutlineChevronRight } from 'react-icons/hi'

import _ from 'lodash'

import { Territory } from '@cozero/models'

import Cascader from '@/atoms/Cascader'

import { CINDER_BLUE_60 } from '@/styles/variables'
import { CascaderNode } from '@/types/general'

import classes from './TerritoryCascader.module.less'

type MenuItemOrDivider = CascaderNode | Divider

class Divider {
  disabled = true
}

const nestTerritories = (territories: Territory[] = []): MenuItemOrDivider[] => {
  const continents: CascaderNode[] = territories
    .filter((territory) => territory.continent?.alpha3Code === 'GLO')
    .map((territory) => {
      return { value: territory.id, label: territory.name, children: [] }
    })
  const possibleTerritories = territories
    .filter(
      (territory) => !!territory.continent?.alpha3Code && territory.continent.alpha3Code !== 'GLO',
    )
    .reduce((sorted, next) => {
      const continent = continents.findIndex((continent) => continent.value === next.continentId)
      if (continent > -1) {
        sorted[continent].children.push({ value: next.id, label: next.name, children: [] })
      }
      return sorted
    }, continents)

  possibleTerritories.forEach((continent: CascaderNode) =>
    continent.children.sort((a, b) => a.label.localeCompare(b.label)),
  )
  possibleTerritories.sort((a, b) => a.label.localeCompare(b.label))

  const globalTerritory = territories.find((territory) => territory.continentId === null)

  const menuItems: MenuItemOrDivider[] = [...possibleTerritories]

  if (globalTerritory) {
    menuItems.push(new Divider(), {
      value: globalTerritory?.id,
      label: globalTerritory?.name,
      children: [],
    })
  }

  return menuItems
}

interface TerritoryCascaderProps {
  options?: Territory[]
  getDefaultValue?: () => number[]
  onChange?: (ids: (number | string)[]) => void
  placeholder?: string
  style?: CSSProperties
  size?: 'large' | 'middle'
  value?: (number | string)[]
}

export const TerritoryCascader = ({
  options,
  getDefaultValue,
  onChange,
  placeholder,
  style,
  size = 'large',
  value,
}: TerritoryCascaderProps): ReactElement => {
  const globalTerritory = options?.find((option) => option.continentId === null)

  value = _.compact(value) // Global comes back from the server as [null, 257]

  const continentId = value[0]
  const terratoryId = value[1]

  if (terratoryId && globalTerritory && continentId === globalTerritory.id) {
    value = [terratoryId]
  }

  const menuItems = nestTerritories(options)

  return (
    <Cascader
      value={value}
      style={style}
      showSearch
      dropdownMatchSelectWidth={true}
      size={size}
      defaultValue={getDefaultValue?.()}
      className={classes.territorySelect}
      options={menuItems}
      suffixIcon={<HiChevronDown size={15} color={CINDER_BLUE_60} />}
      onChange={(event) => onChange && onChange(event)}
      placeholder={placeholder}
      changeOnSelect
      expandIcon={<HiOutlineChevronRight />}
    />
  )
}
