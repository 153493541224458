import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { routes } from '@cozero/utils'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import Button from '../../atoms/Button'

const SupplierBulkFactorRequestsButton = ({
  type = 'secondary',
}: {
  type?: 'primary' | 'secondary'
}): ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Button
      category={AnalyticsCategories.SUPPLIER_BULK_FACTOR_REQUESTS}
      action="Supplier bulk factor requests"
      type={type}
      data-cy="supplier-bulk-factor-requests-btn"
      onClick={() => navigate(routes.log.factors.bulkRequest.base)}
      prefixIcon={<HiOutlinePlus />}
    >
      {t('log.supplier-bulk-request.bulk-request-action')}
    </Button>
  )
}

export default SupplierBulkFactorRequestsButton
