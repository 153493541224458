import {
  CreateSupplierBulkRequestDto,
  PaginatedQueryResult,
  PaginationDto,
  ReuploadFailedFileDto,
  SupplierBulkRequestDto,
  UpdateSupplierBulkRequestDto,
} from '@cozero/dtos'
import { logApiGatewayClient } from '@cozero/uris'

import { ISignedFilesProps } from '@/organisms/FileUploader/FileUploader'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import { PARTIAL_LIST } from '../types'

import {
  CREATE_SUPPLIER_BULK_REQUEST,
  GET_SIGNED_URL,
  GET_SUPPLIER_BULK_REQUEST,
  GET_SUPPLIER_BULK_REQUEST_BY_ID,
  GET_SUPPLIER_BULK_REQUEST_TEMPLATE,
  REUPLOAD_FAILED_FILE,
  TAG_SUPPLIER_BULK_REQUEST,
  UPDATE_ONE_SUPPLIER_BULK_REQUEST,
} from './constants'

const supplierBulkRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_SUPPLIER_BULK_REQUEST]: builder.query<
      PaginatedQueryResult<SupplierBulkRequestDto>,
      PaginationDto
    >({
      query: (params) => {
        return {
          url: logApiGatewayClient.supplierBulkRequests.GET_MANY,
          method: 'GET',
          params,
        }
      },
      providesTags: (result) => providesList(result?.data, TAG_SUPPLIER_BULK_REQUEST, PARTIAL_LIST),
    }),
    [GET_SUPPLIER_BULK_REQUEST_BY_ID]: builder.query<SupplierBulkRequestDto, string>({
      query: (id) => ({
        url: logApiGatewayClient.supplierBulkRequests.GET_ONE.replace(':id', id),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_SUPPLIER_BULK_REQUEST, id: result?.id }],
    }),
    [GET_SUPPLIER_BULK_REQUEST_TEMPLATE]: builder.mutation<void, void>({
      query: () => ({
        url: logApiGatewayClient.supplierBulkRequests.REQUEST_TEMPLATE,
        method: 'POST',
      }),
    }),
    [CREATE_SUPPLIER_BULK_REQUEST]: builder.mutation<
      SupplierBulkRequestDto,
      CreateSupplierBulkRequestDto
    >({
      query: (data) => ({
        url: logApiGatewayClient.supplierBulkRequests.CREATE_MANY,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: TAG_SUPPLIER_BULK_REQUEST, id: PARTIAL_LIST }],
    }),
    [UPDATE_ONE_SUPPLIER_BULK_REQUEST]: builder.mutation<
      SupplierBulkRequestDto,
      UpdateSupplierBulkRequestDto & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: logApiGatewayClient.supplierBulkRequests.UPDATE_ONE_BULK_REQUEST.replace(
          ':id',
          id.toString(),
        ),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: TAG_SUPPLIER_BULK_REQUEST, id: PARTIAL_LIST },
              { type: TAG_SUPPLIER_BULK_REQUEST, id: result.id },
            ]
          : [],
    }),
    [GET_SIGNED_URL]: builder.mutation<ISignedFilesProps[], string[]>({
      query: (fileNames) => ({
        url: logApiGatewayClient.supplierBulkRequests.GET_SIGNED_FILES_URLS,
        method: 'POST',
        params: {
          fileNames: JSON.stringify(fileNames),
        },
      }),
    }),
    [REUPLOAD_FAILED_FILE]: builder.mutation<
      SupplierBulkRequestDto,
      ReuploadFailedFileDto & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: logApiGatewayClient.supplierBulkRequests.REUPLOAD_FAILED_FILE.replace(
          ':id',
          id.toString(),
        ),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: TAG_SUPPLIER_BULK_REQUEST, id: PARTIAL_LIST },
              { type: TAG_SUPPLIER_BULK_REQUEST, id: result.id },
            ]
          : [],
    }),
  }),
})

export const {
  useGetSupplierBulkRequestTemplateMutation,
  useCreateSupplierBulkRequestMutation,
  useGetSupplierBulkRequestSignedUrlsMutation,
  useGetSupplierBulkRequestsQuery,
  useGetSupplierBulkRequestByIdQuery,
  useUpdateOneSupplierBulkRequestMutation,
  useReuploadFailedFileMutation,
} = supplierBulkRequestsApiSlice

export default supplierBulkRequestsApiSlice
