import {
  ComputedReportTableQueryDto,
  ComputedReportTypeDto,
  ComputedReportWithDataDto,
  ComputedReportWithoutDataDto,
  CreateComputedReportDto,
  PaginatedQueryResult,
  UpdateComputedReportDto,
} from '@cozero/dtos'
import { reportsApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import api from '../api'

import {
  CREATE_COMPUTED_REPORT,
  DELETE_ONE_COMPUTED_REPORT,
  GET_COMPUTED_REPORT_TYPES,
  GET_LATEST_COMPUTED_REPORT_DATA,
  GET_MANY_COMPUTED_REPORTS,
  UPDATE_COMPUTED_REPORT,
} from './constants'
import {
  TAG_COMPUTED_REPORT_TYPES,
  TAG_MANY_COMPUTED_REPORTS,
  TAG_ONE_COMPUTED_REPORT,
} from './tags'

export type GetLatestComputedReportParams = {
  businessUnitId: number
  computedReportTypeKey: string
  baseYearStart: string
  baseYearEnd: string
  reportingYearStart: string
  reportingYearEnd: string
}

export interface MockedReportNameMutation {
  name: string
  id: string
}

const ComputedReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_COMPUTED_REPORT_TYPES]: builder.query<ComputedReportTypeDto[], void>({
      query: () => ({
        url: reportsApiGatewayClient.computedReports.GET_ALL_TYPES,
        method: 'GET',
      }),
      providesTags: (result) => providesList(result, TAG_COMPUTED_REPORT_TYPES),
    }),
    [GET_LATEST_COMPUTED_REPORT_DATA]: builder.query<
      ComputedReportWithDataDto,
      GetLatestComputedReportParams
    >({
      query: (params) => ({
        url: reportsApiGatewayClient.computedReports.GET_LAST,
        method: 'GET',
        params,
      }),
      transformErrorResponse: (res) => res,
      providesTags: (result) => [{ type: TAG_ONE_COMPUTED_REPORT, id: result?.id }],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch(api.util.upsertQueryData('getComputedReportById', data.id.toString(), data))
        } catch (error) {
          return error
        }
      },
    }),
    [GET_MANY_COMPUTED_REPORTS]: builder.query<
      PaginatedQueryResult<ComputedReportWithoutDataDto>,
      ComputedReportTableQueryDto
    >({
      query: (params) => ({
        url: reportsApiGatewayClient.computedReports.GET_MANY,
        method: 'GET',
        params,
      }),
      providesTags: (result) => providesList(result?.data, TAG_MANY_COMPUTED_REPORTS),
    }),
    [CREATE_COMPUTED_REPORT]: builder.mutation<ComputedReportWithDataDto, CreateComputedReportDto>({
      query: (data) => ({
        url: reportsApiGatewayClient.computedReports.CREATE,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [TAG_MANY_COMPUTED_REPORTS],
      extraOptions: { maxRetries: 0 },
    }),
    [DELETE_ONE_COMPUTED_REPORT]: builder.mutation<ComputedReportWithDataDto, number>({
      query: (id) => ({
        url: reportsApiGatewayClient.computedReports.DELETE_ONE.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        TAG_MANY_COMPUTED_REPORTS,
        { type: TAG_ONE_COMPUTED_REPORT, id: result?.id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [UPDATE_COMPUTED_REPORT]: builder.mutation<
      ComputedReportWithDataDto,
      { id: string; body: UpdateComputedReportDto }
    >({
      query: ({ id, body }) => ({
        url: reportsApiGatewayClient.computedReports.UPDATE_ONE.replace(':id', id),
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: () => [TAG_MANY_COMPUTED_REPORTS],
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        const patchOp = dispatch(
          api.util.updateQueryData('getComputedReportById', params.id, (report) => {
            Object.assign(report, params.body)
          }),
        )
        try {
          await queryFulfilled
        } catch (error) {
          patchOp.undo()
        }
      },
    }),
  }),
})

export const {
  useGetComputedReportTypesQuery,
  useLazyGetComputedReportByIdQuery,
  useGetLatestComputedReportDataQuery,
  useCreateComputedReportMutation,
  useLazyGetLatestComputedReportDataQuery,
  useGetComputedReportByIdQuery,
  useGetManyComputedReportsDataQuery,
  useDeleteOneComputedReportMutation,
  useUpdateComputedReportMutation,
} = ComputedReportsApiSlice

export default ComputedReportsApiSlice
