import { RcFile } from 'antd/es/upload'

import { DeleteFileFromRequestDto, UploadFilesToSignedUrlRequestDto } from '@cozero/dtos'
import { File, PutSignedUrl } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import axios from '@/utils/axios'

import apiSlice from '../api'
import { TAG_LOG } from '../logs/tags'

const TAG_FILES = 'files'

const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogFiles: builder.query<File[] | null, { logId: number }>({
      query: ({ logId }) => ({
        url: logApiGatewayClient.files.GET_MANY.replace(':id', `${logId}`),
        method: 'GET',
      }),
      providesTags: [{ type: TAG_FILES }],
    }),
    getSignedUrl: builder.mutation<PutSignedUrl[], string[]>({
      query: (fileNames) => ({
        url: centralApiGatewayClient.users.SIGNED_PUT_IMAGE_URL,
        method: 'GET',
        params: {
          fileNames: JSON.stringify(fileNames),
        },
      }),
    }),
    getLogFilesPutUrl: builder.query<PutSignedUrl[], { fileNames: string[] }>({
      query: ({ fileNames }) => ({
        url: logApiGatewayClient.logs.SIGNED_PUT_URL,
        method: 'GET',
        params: {
          fileNames: JSON.stringify(fileNames),
        },
      }),
    }),
    uploadFileToSignedUrl: builder.mutation<
      { path: string },
      { setProgress?: (progress: number) => void } & UploadFilesToSignedUrlRequestDto
    >({
      queryFn: ({ signedUrl, fileObj, setProgress }) => {
        return axios.put(signedUrl, fileObj, {
          headers: {
            'Content-Type': fileObj?.type as string,
            'Content-Length': fileObj?.size?.toString() as string,
          },
          onUploadProgress: (ev) =>
            setProgress?.(Math.round((ev.loaded * 100) / ((ev.total as number) ?? fileObj?.size))),
        })
      },
    }),
    uploadFilesToLog: builder.mutation<void, { logIds: number[]; files: string[] }>({
      query: (data) => ({
        url: logApiGatewayClient.files.UPLOAD,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (request, result, data) => [
        TAG_FILES,
        ...data.logIds.map((logId) => ({ type: TAG_LOG, id: logId })),
      ],
    }),
    deleteFileFromLog: builder.mutation<void, DeleteFileFromRequestDto & { logId: number }>({
      query: ({ logId, fileId }) => ({
        url: logApiGatewayClient.files.DELETE.replace(':id', `${logId}`),
        method: 'DELETE',
        params: {
          fileId,
        },
      }),
      invalidatesTags: (request, result, data) => [TAG_FILES, { type: TAG_LOG, id: data.logId }],
    }),
  }),
})

export const {
  useGetLogFilesQuery,
  useGetSignedUrlMutation,
  useUploadFileToSignedUrlMutation,
  useGetLogFilesPutUrlQuery,
  useLazyGetLogFilesPutUrlQuery,
  useUploadFilesToLogMutation,
  useDeleteFileFromLogMutation,
} = filesApiSlice

export default filesApiSlice
