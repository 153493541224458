export const SLICE_NAME_USER = 'factorRequests'
export const FIND_FACTOR_REQUEST = 'findFactorRequest'
export const FIND_FACTOR_REQUEST_SUPPLIER = 'findFactorRequestSupplier'
export const FIND_FACTOR_REQUEST_BY_ID = 'findFactorRequestById'
export const GET_MANY_INCOMING_REQUESTS = 'getManyIncomingRequests'
export const GET_MANY_OUTGOING_REQUESTS = 'getManyOutgoingRequests'
export const DELETE_ONE_FACTOR_REQUEST = 'deleteOneFactorRequest'
export const UPDATE_ONE_FACTOR_REQUEST = 'updateOneFactorRequest'
export const CREATE_ONE_FACTOR_REQUEST = 'createOneFactorRequest'
export const CREATE_CBAM_PROOF = 'createCbamProof'

export const TAG_MANY_FACTOR_REQUESTS = 'TAG_MANY_FACTOR_REQUESTS'
export const TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS = 'TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS'
export const TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS = 'TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS'
export const TAG_ONE_FACTOR_REQUEST = 'TAG_ONE_FACTOR_REQUEST'
