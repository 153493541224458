/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

import { message } from 'antd'

import Modal from '@/molecules/Modal'

import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useDeleteManyLogEntriesMutation } from '@/redux/logEntries'

import { RowSelectionResult } from '../../../hooks/useRowsSelection'
import { ValidateLogEntriesModal } from '../ValidateLogEntriesModal/ValidateLogEntriesModal'

import classes from './LogEntryDeletionModal.module.less'

type LogEntryDeletionModalProps = {
  open: boolean
  onSuccess: () => void
  onCancel: () => void
  selectedLogEntriesIds: RowSelectionResult['selectedLogEntriesIds']
}

export const LogEntryDeletionModal = ({
  selectedLogEntriesIds = [],
  open,
  onSuccess,
  onCancel,
}: LogEntryDeletionModalProps): React.ReactElement => {
  const { t } = useTranslation()
  const { trackAction } = useMixpanel()

  const [deleteManyLogEntries, { isLoading: isDeleting }] = useDeleteManyLogEntriesMutation()

  const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false)

  const deleteLogEntries = React.useCallback(
    async (logEntriesIds: RowSelectionResult['selectedLogEntriesIds']): Promise<void> => {
      if (!logEntriesIds?.length) {
        return
      }

      trackAction(
        AnalyticsCategories.LOG_ENTRY_OVERVIEW,
        logEntriesIds.length
          ? 'log-entry-overview-bulk-delete-partial-CP-limitations'
          : 'log-entry-overview-bulk-delete',
      )

      const response = await deleteManyLogEntries({ logEntriesIds })

      if ('error' in response) {
        message.warning(t('log.log-entries-overview.actions.delete-selection.error-message'))
        onCancel()
        return
      }

      message.success(
        t('log.log-entries-overview.actions.delete-selection.success-message', {
          count: logEntriesIds.length,
        }),
      )

      onSuccess()
    },
    [trackAction, deleteManyLogEntries, t, onSuccess, onCancel],
  )

  React.useEffect(() => {
    if (!open) {
      setDisplayConfirmationModal(false)
    }
  }, [open])

  // If all log entries are deletable, show the confirmation modal
  if (displayConfirmationModal) {
    return (
      <Modal
        className={classes.modal}
        noBodyPadding
        open={open}
        centered
        onCancel={onCancel}
        title={t('log.log-entries-overview.actions.validate-selection.confirm-selection')}
        onOk={() => deleteLogEntries(selectedLogEntriesIds)}
        okText={t('log.log-entries-overview.actions.delete-selection.ok-text')}
        okButtonProps={{
          color: 'danger',
        }}
        confirmLoading={isDeleting}
      >
        <div className={classes.wrapper}>
          <div className={classes.warningText}>
            <HiOutlineExclamationCircle size={18} />
            <Text size="xl" style={{ textAlign: 'center' }}>
              <Trans
                i18nKey="log.log-entries-overview.actions.delete-selection.confirmation-text"
                values={{ count: selectedLogEntriesIds.length }}
                components={{ bold: <strong /> }}
              />
            </Text>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <ValidateLogEntriesModal
      selectedLogEntriesIds={selectedLogEntriesIds}
      open={open}
      onCancel={onCancel}
      onValid={() => setDisplayConfirmationModal(true)}
      onPartialSubmit={deleteLogEntries}
      actionNotPossibleText={t(
        'log.log-entries-overview.actions.delete-selection.alert-unable-to-delete',
      )}
      actionPartiallyNotPossibleInfoText={t(
        'log.log-entries-overview.actions.delete-selection.alert-with-conflict',
      )}
      actionPartiallyNotPossibleKey="log.log-entries-overview.actions.delete-selection.confirmation-text-with-conflict"
      confirmText={t('log.log-entries-overview.actions.delete-selection.ok-text')}
      confirmButtonProps={{
        color: 'danger',
      }}
    />
  )
}
