/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Modal, { ModalProps } from '@/molecules/Modal'

import Alert from '@/atoms/Alert'
import { SpinnerElement } from '@/atoms/LoadingSpinner'
import Text from '@/atoms/Text'

import { useValidateLogEntriesActionsMutation } from '@/redux/logEntries'
import { CINDER_BLUE_80 } from '@/styles/variables'

import { RowSelectionResult } from '../../../hooks/useRowsSelection'

import classes from './ValidateLogEntriesModal.module.less'

type ValidateLogEntriesModalProps = {
  open: boolean
  selectedLogEntriesIds: RowSelectionResult['selectedLogEntriesIds']
  confirmText: string
  confirmButtonProps?: ModalProps['okButtonProps']
  actionNotPossibleText: string
  actionPartiallyNotPossibleInfoText: string
  actionPartiallyNotPossibleKey: string
  onValid: () => void
  onPartialSubmit: (
    logEntriesIds: RowSelectionResult['selectedLogEntriesIds'],
  ) => void | Promise<void>
  onCancel: () => void
}

export const ValidateLogEntriesModal = ({
  selectedLogEntriesIds = [],
  open,
  confirmText,
  confirmButtonProps,
  actionNotPossibleText,
  actionPartiallyNotPossibleInfoText,
  actionPartiallyNotPossibleKey,
  onValid,
  onPartialSubmit,
  onCancel,
}: ValidateLogEntriesModalProps): React.ReactElement => {
  const { t } = useTranslation()
  const [
    validateLogEntriesActions,
    { data: validationData, isLoading: isValidatingLogEntries, reset: resetValidation },
  ] = useValidateLogEntriesActionsMutation()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onPartialOk = React.useCallback(async () => {
    setIsSubmitting(true)

    await onPartialSubmit(validationData?.editableLogEntriesIds ?? [])

    setIsSubmitting(false)
  }, [onPartialSubmit, validationData?.editableLogEntriesIds])

  React.useEffect(() => {
    if (open && selectedLogEntriesIds.length) {
      validateLogEntriesActions({ logEntriesIds: selectedLogEntriesIds })
    }
  }, [validateLogEntriesActions, open, selectedLogEntriesIds])

  React.useEffect(() => {
    if (!open) {
      resetValidation()
    }
  }, [open, resetValidation])

  React.useEffect(() => {
    if (
      open &&
      !isValidatingLogEntries &&
      validationData?.editableLogEntriesIds?.length === selectedLogEntriesIds.length
    ) {
      onValid()
    }
  }, [
    isValidatingLogEntries,
    onValid,
    open,
    selectedLogEntriesIds.length,
    validationData?.editableLogEntriesIds?.length,
  ])

  // If the log entries are being validated, show the loading modal
  if (isValidatingLogEntries) {
    return (
      <Modal
        className={classes.modal}
        noBodyPadding
        open={open}
        centered
        onCancel={onCancel}
        cancelText={t('log.log-entries-overview.actions.go-back')}
        title={t('log.log-entries-overview.actions.validate-selection.validating')}
      >
        <div className={classes.wrapper}>
          <SpinnerElement size="md" color="grey" />
        </div>
      </Modal>
    )
  }

  // If some log entries are actionnable, show the partial confirmation modal
  if (
    validationData?.editableLogEntriesIds?.length &&
    validationData?.editableLogEntriesIds?.length > 0
  ) {
    return (
      <Modal
        className={classes.modal}
        noBodyPadding
        open={open}
        centered
        onCancel={onCancel}
        title={t('log.log-entries-overview.actions.validate-selection.confirm-selection')}
        onOk={onPartialOk}
        okText={confirmText}
        okButtonProps={confirmButtonProps}
        confirmLoading={isSubmitting}
      >
        <div className={classes.wrapper}>
          <Alert type="warning" textColor={CINDER_BLUE_80} className={classes.alert}>
            {actionPartiallyNotPossibleInfoText}
          </Alert>
          <Text size="xl" style={{ textAlign: 'center' }} className={classes.text}>
            <Trans
              i18nKey={actionPartiallyNotPossibleKey}
              values={{ count: validationData.editableLogEntriesIds.length }}
              components={{ bold: <strong /> }}
            />
          </Text>
        </div>
      </Modal>
    )
  }

  // If no log entries are actionnable, show the action not possible modal
  return (
    <Modal
      className={classes.modal}
      noBodyPadding
      open={open}
      centered
      onCancel={onCancel}
      title={t('log.log-entries-overview.actions.validate-selection.not-possible')}
      cancelText={t('log.log-entries-overview.actions.go-back')}
    >
      <div className={classes.wrapper}>
        <Alert type="warning" textColor={CINDER_BLUE_80} className={classes.alert}>
          {actionNotPossibleText}
        </Alert>
      </div>
    </Modal>
  )
}
