import React, { ReactElement, ReactEventHandler, ReactNode } from 'react'

import { Form as AntDesignForm, FormProps } from 'antd/es'
import { FormInstance } from 'antd/lib/form'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'

interface Props<TFormValues> extends FormProps<TFormValues> {
  category: AnalyticsCategories
  children: ReactNode
  action?: string
  form?: FormInstance<TFormValues>
}

const Form = <TFormValues,>({
  children,
  category,
  action,
  ...rest
}: Props<TFormValues>): ReactElement => {
  const { trackAction } = useMixpanel()

  const onError: ReactEventHandler<HTMLFormElement> = (event) => {
    if (action) {
      trackAction(category, action)
    }
    rest.onError?.(event)
  }

  const onFinish: Props<TFormValues>['onFinish'] = (values) => {
    if (action) {
      trackAction(category, action)
    }
    rest.onFinish?.(values)
  }

  return (
    <AntDesignForm onFinish={onFinish} onError={onError} {...rest}>
      {children}
    </AntDesignForm>
  )
}

Form.Item = AntDesignForm.Item
Form.List = AntDesignForm.List
Form.useForm = AntDesignForm.useForm
Form.Provider = AntDesignForm.Provider
Form.ErrorList = AntDesignForm.ErrorList
Form.useFormInstance = AntDesignForm.useFormInstance
Form.useWatch = AntDesignForm.useWatch

export default Form
