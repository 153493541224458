import { useEffect } from 'react'

import { t } from 'i18next'
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs'

import { ROUTES } from '@/templates/Router'

export const usePageTitle = (): void => {
  const breadcrumbs = useBreadcrumbs(ROUTES(t))

  useEffect(() => {
    document.title = `Cozero.io - ${getPageTitle(breadcrumbs)}`
  }, [location.pathname])
}

const getPageTitle = (breadcrumbs: BreadcrumbData[]): string => {
  const defaultTitle = 'Climate management software'
  try {
    const lastCrumb = breadcrumbs.pop()

    // Custom breadcrumbs can contain components, in these cases we cannot easily derive the title
    const lastCrumbIsString = typeof lastCrumb?.match.route?.breadcrumb === 'string'

    if (lastCrumbIsString) {
      return `${lastCrumb.match.route?.breadcrumb}`
    }

    const { pathname } = location

    // The filter remove empty url sections due to trailing slashes.
    const lastSection = pathname.split('/').filter(Boolean).pop()

    if (!lastSection) {
      return defaultTitle
    }

    const formattedTitle = lastSection
      .replace(/-/g, ' ')
      .replace(/^(\w)/, (char) => char.toUpperCase())

    return decodeURIComponent(formattedTitle)
  } catch (error) {
    return defaultTitle
  }
}
