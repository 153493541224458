/* eslint react-hooks/exhaustive-deps: 2 */
import React, { ReactElement } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { HiInformationCircle } from 'react-icons/hi'

import { ColumnType } from 'antd/es/table'

import classNames from 'classnames'
import i18n from 'i18next'
import { get, range } from 'lodash-es'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'
import { LogEntriesTableColumnKey } from '@cozero/models'

import {
  renderCategory,
  renderDate,
  renderEmissions,
  renderId,
  renderTags,
  renderText,
  renderUser,
} from '@/molecules/Table/table-cell-renders/table-cell-renders'

import Tooltip from '@/atoms/Tooltip'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { selectCurrentPageResults } from '@/redux/logEntriesOverview/selectors'
import { COZERO_BLUE_80 } from '@/styles/variables'
import { formatNumber } from '@/utils/number'

import classes from '../classes.module.less'

export type TableColumns = (Omit<ColumnType<OrganizationCarbonFootprintSearchDto>, 'title'> & {
  title: string | ReactElement
  columnManagerTitle?: string
  key: LogEntriesTableColumnKey
  sortable?: boolean
  draggable?: boolean
})[]

const generateInputColumns = (t: TFunction): TableColumns => {
  return range(15).map((index) => {
    const isFirst = index === 0
    const displayIndex = index + 1

    return {
      key: `input${displayIndex}Value` as LogEntriesTableColumnKey,
      dataIndex: ['inputs', `input${displayIndex}Value`],
      width: 0,
      sortable: true,
      draggable: isFirst,
      columnManagerTitle: isFirst ? t('log.log-entries-overview.table-columns.inputs') : undefined,
      title: t('log.log-entries-overview.table-columns.input', { index: displayIndex }),
      render: (value: string, record: OrganizationCarbonFootprintSearchDto) => {
        const formatInputValue = (value: string, unitName: string): string => {
          if (value === null || value === '') {
            return ''
          }

          return `${formatNumber(Number(value))} ${unitName}`
        }

        const formatUnitName = (record: OrganizationCarbonFootprintSearchDto): string =>
          record.inputs[`input${displayIndex}UnitName` as keyof typeof record.inputs] as string

        return renderTags({
          tags: [formatInputValue(value, formatUnitName(record))],
          classNames: record.modeledImpact?.id ? classes.ghostTag : undefined,
          showHyphenWhenEmpty: false,
          align: 'end',
        })
      },
    }
  })
}

const filterEmptyColumns = ({
  columnsData,
  tableColumns,
}: {
  columnsData?: OrganizationCarbonFootprintSearchDto[]
  tableColumns: TableColumns
}): TableColumns => {
  if (!columnsData?.length) {
    return tableColumns
  }

  return tableColumns.filter((column) => {
    if (!column.key.startsWith('input') || column.key.match(/^input1(Value|Unit)$/)) {
      return true
    }

    const allValues = columnsData.map((columnData) =>
      get(columnData, column.dataIndex as string[] | string),
    )

    return !allValues.every((value) => value === null || value === undefined || value === '')
  })
}

const getColumns = (t: TFunction, featuresAllowed: string[]): TableColumns => {
  const inputColumns = generateInputColumns(t)

  return [
    {
      key: LogEntriesTableColumnKey.LOG_ID,
      title: t('log.log-entries-overview.table-columns.log-id'),
      sortable: true,
      dataIndex: ['log', 'id'],
      width: 0,
      render: (id: string) => renderId(id, t),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.LOG_ENTRY_ID,
      title: t('log.log-entries-overview.table-columns.log-entry-id'),
      sortable: true,
      dataIndex: ['logEntry', 'id'],
      width: 0,
      render: (id: string) => renderId(id, t),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.TITLE,
      title: t('log.log-entries-overview.table-columns.log-entry-title'),
      sortable: true,
      dataIndex: ['logEntry', 'title'],
      render: (text: string) => renderText(text),
      draggable: true,
    },
    {
      className: classes.descriptionColumn,
      key: LogEntriesTableColumnKey.DESCRIPTION,
      title: t('log.log-entries-overview.table-columns.log-description'),
      sortable: true,
      dataIndex: ['log', 'description'],
      render: (text: string) => renderText(text),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.ACTIVTY_DATA_SOURCE,
      title: t('log.log-entries-overview.table-columns.business-activity'),
      width: 0,
      sortable: true,
      dataIndex: ['activityDataSource', 'name'],
      render: (text: string) => renderTags({ tags: [text] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.CATEGORY,
      title: t('log.log-entries-overview.table-columns.category'),
      width: 0,
      sortable: true,
      dataIndex: ['category'],
      render: renderCategory,
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.MODELED_IMPACT,
      title: t('log.log-entries-overview.table-columns.emission-type'),
      width: 0,
      sortable: true,
      dataIndex: ['modeledImpact', 'name'],
      render: (text: string) => text && renderTags({ tags: [text] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.SUBCATEGORY,
      title: t('log.log-entries-overview.table-columns.subcategory'),
      width: 0,
      sortable: true,
      dataIndex: ['subcategory', 'name'],
      render: (text: string) => renderText(text),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.BUSINESS_UNIT,
      title: t('log.log-entries-overview.table-columns.business-unit'),
      width: 0,
      sortable: true,
      dataIndex: ['businessUnit', 'name'],
      render: (businessUnit: string) => renderTags({ tags: [businessUnit] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.DATA_QUALITY,
      title: t('log.log-entries-overview.table-columns.data-quality'),
      width: 0,
      sortable: true,
      dataIndex: ['logEntry', 'dataQuality'],
      render: (dataQuality: string) =>
        renderTags({ tags: [t(`data-quality.options.${dataQuality}.name`)] }),
    },
    {
      key: LogEntriesTableColumnKey.LOCATION,
      title: t('log.log-entries-overview.table-columns.location'),
      width: 0,
      sortable: true,
      dataIndex: ['location', 'name'],
      render: (text: string) => renderTags({ tags: [text] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.LOCATION_TAGS,
      title: t('log.log-entries-overview.table-columns.location-tags'),
      width: 0,
      sortable: false,
      dataIndex: ['location', 'tags'],
      render: (tags: string[]) => renderTags({ tags }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.FILES,
      title: t('log.log-entries-overview.table-columns.files'),
      width: 0,
      sortable: true,
      dataIndex: ['filesTotal'],
      render: (filesTotal: number) => renderText(`${filesTotal}`),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.OWNER,
      title: t('log.log-entries-overview.table-columns.responsible'),
      width: 0,
      sortable: true,
      dataIndex: ['owner'],
      render: renderUser,
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.SUPPLIER,
      title: t('log.log-entries-overview.table-columns.supplier'),
      width: 0,
      sortable: true,
      dataIndex: ['supplier', 'name'],
      render: (text: string) => renderTags({ tags: [text] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.START_DATE,
      title: t('log.log-entries-overview.table-columns.period-start'),
      width: 0,
      sortable: true,
      dataIndex: ['log', 'startDate'],
      render: (date: string) => renderDate(date),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.END_DATE,
      title: t('log.log-entries-overview.table-columns.period-end'),
      width: 0,
      sortable: true,
      dataIndex: ['log', 'endDate'],
      render: (date: string) => renderDate(date),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.CREATED_AT,
      title: t('log.log-entries-overview.table-columns.created-at'),
      width: 0,
      sortable: true,
      dataIndex: ['log', 'createdAt'],
      render: (date: string) => renderDate(date, { showTime: true }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.TERRITORY,
      title: t('log.log-entries-overview.table-columns.territory'),
      width: 0,
      sortable: true,
      dataIndex: 'territory',
      render: (territory) => renderTags({ tags: [territory.name] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.ORGANIZATION_TAGS,
      title: t('log.log-entries-overview.table-columns.organization-tags'),
      width: 0,
      dataIndex: ['logEntry', 'tags'],
      render: (tags?: string[]) => (tags?.length ? renderTags({ tags }) : null),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.PRODUCT_TAGS,
      title: t('log.log-entries-overview.table-columns.product-tags'),
      width: 0,
      dataIndex: ['product', 'tags'],
      render: (tags?: string[]) => (tags?.length ? renderTags({ tags }) : null),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.CALCULATION_METHOD,
      title: t('log.log-entries-overview.table-columns.calculation-method'),
      width: 0,
      sortable: true,
      dataIndex: 'calculationMethod',
      render: (calculationMethod) => renderTags({ tags: [calculationMethod.name] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.SCOPE,
      title: t('log.log-entries-overview.table-columns.scope'),
      width: 0,
      dataIndex: 'scope',
      sortable: true,
      draggable: true,
      render: (scope) => {
        if (!scope.category) {
          return null
        }

        return renderText(
          `${scope.category}${scope.subcategory ? `.${scope.subcategory}` : ''} - ${scope.title}`,
        )
      },
    },
    {
      key: LogEntriesTableColumnKey.EMISSION_FACTOR_VALUE,
      title: t('log.log-entries-overview.table-columns.emission-factor-value'),
      width: 0,
      dataIndex: ['emissionFactor', 'value'],
      sortable: true,
      render: (value) => renderTags({ tags: [value?.toLocaleString(i18n.language)] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.EMISSION_FACTOR_UNIT,
      title: t('log.log-entries-overview.table-columns.emission-factor-unit'),
      width: 0,
      dataIndex: ['emissionFactor', 'unit'],
      sortable: true,
      render: (value) => renderTags({ tags: [value] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.EMISSION_FACTOR_YEAR,
      title: t('log.log-entries-overview.table-columns.emission-factor-year'),
      width: 0,
      dataIndex: ['emissionFactor', 'year'],
      sortable: true,
      render: (value) => renderTags({ tags: [value] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.EMISSION_FACTOR_SOURCE,
      title: t('log.log-entries-overview.table-columns.emission-factor-source'),
      width: 0,
      dataIndex: ['emissionFactor', 'source'],
      sortable: true,
      render: (value) => renderTags({ tags: [value] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.EMISSION_FACTOR_TERRITORY,
      title: t('log.log-entries-overview.table-columns.emission-factor-territory'),
      width: 0,
      dataIndex: ['emissionFactor', 'territory'],
      sortable: true,
      render: (value) => renderTags({ tags: [value] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.EMISSION_FACTOR_DESCRIPTION,
      title: t('log.log-entries-overview.table-columns.emission-factor-description'),
      width: 0,
      dataIndex: ['emissionFactor', 'description'],
      sortable: true,
      render: (text: string) => renderText(text, 300),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.ASSESSMENT_REPORT,
      title: t('log.log-entries-overview.table-columns.assessment-report'),
      width: 0,
      dataIndex: ['emissionFactor', 'assessmentReport'],
      sortable: true,
      render: (value) => renderTags({ tags: [value] }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.ENERGY_CONSUMPTION,
      title: t('log.log-entries-overview.table-columns.energy-consumption'),
      width: 0,
      sortable: true,
      dataIndex: 'energyConsumption',
      render: (value, record) => renderEmissions(value, { ghost: !!record.modeledImpact?.id }),
      draggable: true,
    },
    {
      key: LogEntriesTableColumnKey.LOCATION_BASED_EMISSIONS_SUM,
      title: t('log.log-entries-overview.table-columns.location-based-emissions'),
      width: 0,
      sortable: true,
      dataIndex: 'locationBasedEmissionsSum',
      render: (value) => renderEmissions(value, { bold: true }),
      draggable: true,
    },
    ...(featuresAllowed.includes('cbam')
      ? [
          {
            key: LogEntriesTableColumnKey.CBAM_EMISSIONS_SUM,
            title: (
              <div className={classes.center}>
                <span>{t('log.log-entries-overview.table-columns.cbam-emissions')}</span>
                <Tooltip
                  className={classNames(classes.center, classes.paddingLeft)}
                  content={t('log.cbamTotalTooltip')}
                  showArrow
                >
                  <HiInformationCircle color={COZERO_BLUE_80} />
                </Tooltip>
              </div>
            ),
            columnManagerTitle: t('log.log-entries-overview.table-columns.cbam-emissions'),
            width: 0,
            sortable: true,
            dataIndex: 'cbamEmissionsSum',
            render: (value: number) => renderEmissions(value),
            draggable: true,
          },
        ]
      : []),
    {
      key: LogEntriesTableColumnKey.ORGANIZATION_EMISSIONS,
      title: t('log.log-entries-overview.table-columns.emissions'),
      width: 0,
      fixed: 'right',
      sortable: true,
      dataIndex: 'emissionsSum',
      render: (value) => renderEmissions(value, { bold: true }),
      draggable: true,
    },
    ...inputColumns,
  ]
}

export const useTableColumns = (): {
  tableColumns: TableColumns
} => {
  const { t } = useTranslation()
  const { featuresAllowed } = usePricingFeature()
  const columnsData = useAppSelector(selectCurrentPageResults)

  const tableColumns = React.useMemo(() => {
    const tableColumns = getColumns(t, featuresAllowed)
    return filterEmptyColumns({ columnsData, tableColumns })
  }, [t, featuresAllowed, columnsData])

  return { tableColumns }
}
