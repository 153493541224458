/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useDispatch } from 'react-redux'

import { GetRowKey, TableRowSelection } from 'antd/lib/table/interface'

import { uniqBy } from 'lodash-es'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'

import { useAppSelector } from '@/redux'
import { selectOrganizationEmissionsSelectedRows } from '@/redux/logEntriesOverview/selectors'
import { resetSelectedRows, setSelectedRows } from '@/redux/logEntriesOverview/slice'

export type RowSelectionResult = {
  rowKey: string | GetRowKey<OrganizationCarbonFootprintSearchDto>
  rowSelection: TableRowSelection<OrganizationCarbonFootprintSearchDto>
  selectedLogEntriesIds: number[]
  selectedRows: OrganizationCarbonFootprintSearchDto[]
  clearSelection: () => void
}

export const useRowSelection = (): RowSelectionResult => {
  const dispatch = useDispatch()
  const selection = useAppSelector(selectOrganizationEmissionsSelectedRows)

  const rowSelection: TableRowSelection<OrganizationCarbonFootprintSearchDto> = {
    type: 'checkbox',
    selectedRowKeys: selection?.keys ?? [],
    preserveSelectedRowKeys: true,
    onChange: (keys: React.Key[], selectedRows) => {
      const uniqRows = uniqBy(selectedRows, 'logEntry.id')
      dispatch(
        setSelectedRows({
          keys,
          ids: uniqRows.map(({ logEntry }) => logEntry.id),
          rows: selectedRows,
        }),
      )
    },
  }

  const clearSelection = React.useCallback(() => {
    dispatch(resetSelectedRows())
  }, [dispatch])

  return {
    rowKey: (record, index) => {
      return `${record.logEntry.id}.${index}`
    },
    rowSelection,
    selectedLogEntriesIds: selection?.ids ?? [],
    selectedRows: selection.rows,
    clearSelection,
  }
}
