import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineCheckCircle, HiOutlineX } from 'react-icons/hi'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Drawer, Row, Spin, Tabs } from 'antd'

import { UserUpdateStatus } from '@prisma/client'

import { Shape } from '@/molecules/Shape/Shape'

import Avatar from '@/atoms/Avatar'
import Button from '@/atoms/Button'
import Divider from '@/atoms/Divider'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { USER_UPDATES_LIMIT } from '@/redux/userUpdates'
import { COZERO_BLUE_80 } from '@/styles/variables'
import { getTimeAgoShort, prettifyDate } from '@/utils/date'

import classes from './UpdatesDrawer.module.less'
import { getAction, useUpdatesDrawer } from './useUpdatesDrawer'

export const UpdatesDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}): JSX.Element => {
  const { t } = useTranslation('common')
  const {
    userUpdatesData,
    userUpdatesTotal,
    handleClose,
    markAllAsRead,
    markAsRead,
    fetchUserUpdates,
  } = useUpdatesDrawer({ onClose })
  const [page, setPage] = useState(1)
  // Only render the infinite scroll once the drawer is fully open
  const [afterOpenChange, setAfterOpenChange] = useState<boolean>(false)
  const [selectedFilter, setSelectedFilter] = useState<'ALL' | 'UNREAD'>('ALL')

  const userUpdateItemsComponent = useMemo((): React.ReactNode | React.ReactNode[] => {
    const filteredData = userUpdatesData.filter(
      (item) => selectedFilter === 'ALL' || item.status === selectedFilter,
    )
    if (!filteredData.length) {
      return selectedFilter === 'UNREAD' ? (
        <Text size="xl">{t('dashboard.home.all-updates.no-updates-unread')}</Text>
      ) : (
        <Text size="xl">{t('dashboard.home.all-updates.no-updates')}</Text>
      )
    }
    return filteredData.map((item) => {
      const { key, ...values } = getAction(item.type, t, item.context, item.doneBy?.name)
      return (
        <div className={classes.container} key={item.id}>
          <div className={classes.updateRow}>
            <div className={classes.updateInfoContainer}>
              <div className={classes.dotContainer}>
                {item.status === UserUpdateStatus.UNREAD && (
                  <Shape type={'dot'} color={COZERO_BLUE_80} />
                )}
              </div>
              <div className={classes.avatarContainer}>
                <Avatar
                  firstName={item.doneBy?.firstName}
                  lastName={item.doneBy?.lastName}
                  image={item.doneBy?.photo}
                  className={classes.userPhoto}
                  showTooltip={false}
                />
              </div>

              <span className={classes.action}>
                <Trans
                  i18nKey={key}
                  values={values}
                  components={{
                    container: <span className={classes.userNameContainer} />,
                    tooltip: (
                      <Tooltip content={item.doneBy?.name} align={{ offset: [0, -4] }}>
                        {''}
                      </Tooltip>
                    ),
                    text: (
                      <Text size="xl" ellipsis>
                        {''}
                      </Text>
                    ),
                    username: <span className={classes.userName} />,
                    entity: <span className={classes.entityName} />,
                  }}
                ></Trans>
              </span>
            </div>
            <div className={classes.timeAgoContainer}>
              <Tooltip content={prettifyDate(item.createdAt)} align={{ offset: [0, -4] }}>
                <Text size="lg" className={classes.date} color="secondary">
                  {getTimeAgoShort(item.createdAt)}
                </Text>
              </Tooltip>
              {item.status === UserUpdateStatus.UNREAD && (
                <div className={classes.markAsReadContainer}>
                  <Button
                    type="text"
                    onClick={() => markAsRead(item.id, page, selectedFilter)}
                    prefixIcon={<HiOutlineCheckCircle size={16} />}
                    className={classes.markAsReadButton}
                  >
                    {t('dashboard.home.all-updates.mark-as-read')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Divider color="light" className={classes.divider} />
        </div>
      )
    })
  }, [userUpdatesData])

  const infiniteScrollComponent = useMemo(() => {
    const drawerBody = document.querySelector('.ant-drawer-body')

    if (drawerBody) {
      drawerBody.id = 'scrollable-container'
    }

    return (
      isOpen &&
      afterOpenChange && (
        <InfiniteScroll
          key={`${selectedFilter}-${page}`}
          dataLength={userUpdatesTotal}
          next={() => setPage(page + 1)}
          // This doens't run when userUpdatesTotal is 0, so we need to check if userUpdatesData.length is greater than 0
          hasMore={!!userUpdatesData.length && userUpdatesData.length < userUpdatesTotal}
          loader={
            <Row justify="center" align="middle" className={classes.spinnerContainer}>
              <Spin />
            </Row>
          }
          scrollableTarget="scrollable-container"
        >
          {userUpdateItemsComponent}
        </InfiniteScroll>
      )
    )
  }, [isOpen, userUpdatesData, userUpdatesTotal, afterOpenChange])

  useEffect(() => {
    if (isOpen) {
      fetchUserUpdates(page, USER_UPDATES_LIMIT, selectedFilter === 'UNREAD' ? true : false)
    }
  }, [isOpen, page, selectedFilter])

  return (
    <Drawer
      title={t('dashboard.home.all-updates.title')}
      placement="left"
      onClose={() => {
        setPage(1)
        setAfterOpenChange(false)
        handleClose()
      }}
      open={isOpen}
      afterOpenChange={() => {
        setAfterOpenChange(true)
      }}
      width={400}
      closeIcon={
        <div className={classes.closeIcon}>
          <HiOutlineX size={16} />
        </div>
      }
    >
      <div className={classes.filtersContainer}>
        <div className={classes.filtersSection}>
          <div className={classes.tabsContainer}>
            <Tabs
              items={[
                { label: t('dashboard.home.all-updates.filter.all'), key: 'ALL' },
                { label: t('dashboard.home.all-updates.filter.unread'), key: 'UNREAD' },
              ]}
              activeKey={selectedFilter}
              onChange={(key) => {
                setSelectedFilter(key as 'ALL' | 'UNREAD')
                setPage(1)
              }}
            />
          </div>
          <Button
            type="text"
            prefixIcon={<HiOutlineCheckCircle size={16} />}
            onClick={() => markAllAsRead(page, selectedFilter)}
          >
            {t('dashboard.home.all-updates.filter.mark-all-as-read')}
          </Button>
        </div>
        <Divider color="light" className={classes.drawerSectionDivider} />
      </div>
      {infiniteScrollComponent}
    </Drawer>
  )
}
