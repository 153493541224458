import { ComputedReportWithDataDto } from '@cozero/dtos'
import { reportsApiGatewayClient } from '@cozero/uris'

import { RtkEndpointBuilderArg } from '@/utils/redux'

import { GET_COMPUTED_REPORT_BY_ID } from './constants'
import { TAG_ONE_COMPUTED_REPORT } from './tags'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const buildComputedReportsMainSliceEndpoints = (builder: RtkEndpointBuilderArg) => ({
  [GET_COMPUTED_REPORT_BY_ID]: builder.query<ComputedReportWithDataDto, string>({
    query: (id) => ({
      url: reportsApiGatewayClient.computedReports.GET_ONE.replace(':id', id),
      method: 'GET',
    }),
    providesTags: (result) => [{ type: TAG_ONE_COMPUTED_REPORT, id: result?.id }],
  }),
})
