import { createApi } from '@reduxjs/toolkit/query/react'

import apiSlice from './api'

export type PARTIAL_LIST_TYPE = 'PARTIAL-LIST'
export type LIST_TYPE = 'LIST'

export const LIST = 'LIST'
export const COUNT = 'COUNT'
export const PARTIAL_LIST = 'PARTIAL-LIST'

export interface BaseMetaResponse {
  response: {
    headers: Headers
  }
}

export type RTKEndpointBuilderArg = Parameters<
  Parameters<ReturnType<typeof createApi>['injectEndpoints']>[0]['endpoints']
>[0]
