import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInboxIn } from 'react-icons/hi'
import { Outlet, useLocation, useNavigate } from 'react-router'

import { Col, Row, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'

import { routes } from '@cozero/utils'

import FactorRequestButton from '@/molecules/FactorRequestButton'
import SupplierBulkFactorRequestsButton from '@/molecules/SupplierBulkFactorRequestsButton'

import OutgoingDocument from '@/atoms/Icons/Outlines/OutgoingDocument'

import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'
import { usePricingFeature } from '@/hooks/usePricingFeature'

import classes from './FactorRequests.module.less'

const FactorRequests = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isFeatureEnabled } = usePricingFeature()

  const orgIsSupplier = useOrgIsSupplier()

  const MENU_ITEMS = useMemo(() => {
    return [
      {
        uri: `${routes.log.factors.requestsPage.outgoingRequest}`,
        title: (
          <span className={classes.tabIcon}>
            <OutgoingDocument />
            {t('customer-requests.outgoing-request')}
          </span>
        ),
        key: 'outgoing-request',
      },
      {
        uri: `${routes.log.factors.requestsPage.incomingRequest}`,
        title: (
          <span className={classes.tabIcon}>
            <HiOutlineInboxIn />
            {t('customer-requests.incoming-request')}
          </span>
        ),
        key: 'incoming-request',
      },
    ]
  }, [])

  const defaultTab = useMemo(
    () => (orgIsSupplier ? MENU_ITEMS[1] : MENU_ITEMS[0]),
    [orgIsSupplier, MENU_ITEMS],
  )

  const currentTab = useMemo(
    () => MENU_ITEMS.find((item) => item.uri === pathname) ?? defaultTab,
    [MENU_ITEMS, pathname],
  )

  const _Tabs = useMemo(
    () => (
      <Tabs
        activeKey={currentTab.uri}
        defaultActiveKey={defaultTab.uri}
        onTabClick={(key) => navigate(key)}
        className={classes.factorTabs}
      >
        {MENU_ITEMS.map(({ uri, title }) => (
          <TabPane key={uri} tab={<span className={classes.tabPane}>{title}</span>}>
            <Outlet />
          </TabPane>
        ))}
      </Tabs>
    ),
    [currentTab, MENU_ITEMS, navigate],
  )

  return (
    <div className={classes.innerContainer}>
      <div className={classes.headerRow}>
        <Row gutter={16}>
          {isFeatureEnabled('supplier-bulk-request') && !orgIsSupplier && (
            <Col>
              <SupplierBulkFactorRequestsButton />
            </Col>
          )}
          {isFeatureEnabled('supplier-engagement') && !orgIsSupplier && (
            <Col>
              <FactorRequestButton />
            </Col>
          )}
        </Row>
      </div>
      {_Tabs}
    </div>
  )
}

export default FactorRequests
