import React from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'

import { SerializedError } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

interface UseRedirectArgs {
  fallback: (payload: unknown, navigate: NavigateFunction) => void
}

export const useRedirect = (args?: UseRedirectArgs): void => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')
  const token = searchParams.get('token')

  React.useEffect(() => {
    if (redirect) {
      navigate(redirect)
    } else if (args?.fallback && token) {
      const tokenPayload = jwtDecode(token)
      if (!tokenPayload) {
        return
      }

      args?.fallback(tokenPayload, navigate)
    }
  }, [redirect, token])
}

export const use404Redirect = (
  error:
    | {
        message: string
        status?: number | undefined
        data?: unknown
      }
    | SerializedError
    | undefined,
  basePath: string,
): void => {
  const navigate = useNavigate()

  React.useEffect(() => {
    if (error && 'status' in error && (error.status === 400 || error.status === 404)) {
      navigate(`${basePath}/404`)
    }
  }, [error, basePath])
}
