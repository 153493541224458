import React, { useCallback, useEffect, useState } from 'react'
import { HiChevronDown } from 'react-icons/hi'

import { orderBy } from 'lodash'

import { ActivityDataSourceWithChildren } from '@cozero/models'

import Cascader from '@/atoms/Cascader'

import { CINDER_BLUE_60 } from '@/styles/variables'

interface ActivityDataSourceLazyLoadedCascaderProps {
  activityDataSources: ActivityDataSourceWithChildren[] | undefined
  disabled?: boolean
  value?: number
  onChange?: (ads: number[]) => void
  placeholder?: string
  dropdownMatchSelectWidth?: boolean
  loading: boolean
}

interface CascaderOption {
  label: string
  value: number
  children: CascaderOption[]
  isLeaf: boolean
}

const ActivityDataSourceLazyLoadedCascader = ({
  activityDataSources,
  disabled,
  placeholder,
  dropdownMatchSelectWidth = true,
  loading,
  onChange,
}: ActivityDataSourceLazyLoadedCascaderProps): JSX.Element => {
  const [activityDataSourcesToDisplay, setActivityDataSourcesToDisplay] = useState<
    ActivityDataSourceWithChildren[]
  >([])
  const [adsStructurePath, setAdsStructurePath] = useState<number[]>([])

  useEffect(() => {
    if (!activityDataSources) {
      return
    }
    if (activityDataSources.length && !activityDataSourcesToDisplay.length) {
      setActivityDataSourcesToDisplay(activityDataSources)
    }
  }, [activityDataSources, activityDataSourcesToDisplay])

  const parseActivityDataSources = (
    activityDataSources: ActivityDataSourceWithChildren[],
  ): CascaderOption[] => {
    const parsedActivityDataSources = []
    for (const ads of activityDataSources) {
      parsedActivityDataSources.push({
        label: ads.name,
        value: ads.id,
        children: ads?.children?.length ? parseActivityDataSources(ads.children) : [],
        isLeaf: !ads?.children?.length,
      })
    }
    return orderBy(parsedActivityDataSources, ['label'], ['asc'])
  }

  const adsOptions = useCallback(() => {
    if (activityDataSourcesToDisplay) {
      return parseActivityDataSources(activityDataSourcesToDisplay)
    }
  }, [activityDataSourcesToDisplay])

  return (
    <Cascader
      value={adsStructurePath}
      disabled={disabled}
      options={adsOptions()}
      placeholder={placeholder}
      changeOnSelect
      loading={loading ?? !activityDataSources}
      suffixIcon={<HiChevronDown size={15} color={CINDER_BLUE_60} />}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      onChange={(value) => {
        onChange && onChange(value as number[])
        setAdsStructurePath(value as number[])
      }}
      showSearch={true}
    />
  )
}

export default ActivityDataSourceLazyLoadedCascader
