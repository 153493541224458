import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { message } from 'antd'

import { FactorRequestStatus } from '@prisma/client'

import { FactorRequestDetailsView } from '@cozero/models'
import { routes } from '@cozero/utils'

import { ISignedFilesProps } from '@/pages/Share/SupplierEngagement/Cbam/components/FileUploader/FileUploader'

import { useUpdateOneFactorRequestMutation } from '@/redux/factors-requests'
import { useUpdateOneFactorRequestResponseMutation } from '@/redux/factors-requests-responses'

import useSupplierOnboarding, { CommonSupplierOnboardingProps } from './useSupplierOnboarding'

type UseCbamOnboardingArgs = {
  factorRequest: FactorRequestDetailsView | undefined
  updateFactorRequestResponse: ReturnType<typeof useUpdateOneFactorRequestResponseMutation>[0]
  saveFactorRequest: ReturnType<typeof useUpdateOneFactorRequestMutation>[0]
  uploadAttachments: () => Promise<ISignedFilesProps[]>
} & Pick<ReturnType<typeof useSupplierOnboarding>, 'comment'>

export const useCbamOnboarding = ({
  factorRequest,
  comment,
  saveFactorRequest,
  updateFactorRequestResponse,
  uploadAttachments,
}: UseCbamOnboardingArgs): CommonSupplierOnboardingProps => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goToCreatePage = async (): Promise<void> => {
    if (!factorRequest) {
      return
    }

    navigate(
      routes.share.supplierEngagementCbam.uploadTemplate.replace(
        ':id',
        factorRequest.id.toString(),
      ),
    )
  }

  const onSubmit = async (): Promise<void> => {
    if (!factorRequest || !factorRequest.factorRequestResponse) {
      return
    }

    const attachments = await uploadAttachments()

    const factorRequestResponseUpdateResponse = await updateFactorRequestResponse({
      id: factorRequest.factorRequestResponse.id,
      data: {
        attachments: attachments.map(({ path, name }) => ({ path, name })),
        message: comment,
      },
    })

    const factorRequestUpdateResponse = await saveFactorRequest({
      ...factorRequest,
      denominatorUnitId: factorRequest.denominatorUnitId,
      status: FactorRequestStatus.SUBMITTED,
    })

    if (
      [factorRequestResponseUpdateResponse, factorRequestUpdateResponse].some(
        (res) => 'error' in res,
      )
    ) {
      message.error(t('suppliers.error'))
    }
  }

  return {
    goToCreatePage,
    onSubmit,
  }
}
