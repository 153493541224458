/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useDispatch } from 'react-redux'

import moment, { Moment } from 'moment'

import { useAppSelector } from '@/redux'
import { selectOrganizationEmissionsDateFilter } from '@/redux/logEntriesOverview/selectors'
import { setOrganizationEmissionsDateFilter } from '@/redux/logEntriesOverview/slice'

type UseDateFilterReturn = {
  onFilterChange: (value: [Moment | null, Moment | null] | null) => void
  dateFilter: { startDate: string | undefined; endDate: string | undefined } | undefined
  ranges: {
    [key: string]: [moment.Moment | null, moment.Moment | null]
  }
}

export const useDateFilter = (): UseDateFilterReturn => {
  const dispatch = useDispatch()
  const dateFilterState = useAppSelector(selectOrganizationEmissionsDateFilter)

  const onFilterChange = React.useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const startDate = value?.[0] ? value[0].tz(timezone).format('YYYY-MM-DD') : null
      const endDate = value?.[1] ? value[1].tz(timezone).format('YYYY-MM-DD') : null

      dispatch(setOrganizationEmissionsDateFilter([startDate, endDate]))
    },
    [dispatch],
  )

  const ranges = React.useMemo(() => {
    const rangesAux: { [key: string]: [Moment | null, Moment | null] } = {}
    rangesAux['Year-to-Date'] = [moment().startOf('year'), moment()]
    rangesAux['Last 12 months'] = [moment().subtract(12, 'months'), moment()]
    for (let i = 3; i >= 0; i--) {
      rangesAux[moment().subtract(i, 'year').year()] = [
        moment().subtract(i, 'year').startOf('year'),
        moment().subtract(i, 'year').endOf('year'),
      ]
    }
    return rangesAux
  }, [])

  const dateFilter = React.useMemo(() => {
    if (!dateFilterState[0] || !dateFilterState[1]) {
      return
    }

    return {
      startDate: dateFilterState[0] || undefined,
      endDate: dateFilterState[1] || undefined,
    }
  }, [dateFilterState])

  return {
    onFilterChange,
    dateFilter,
    ranges,
  }
}
