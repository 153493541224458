import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import NotFoundPage from '@/pages/404'
import ClosedPeriods from '@/pages/ClosedPeriods'
import AddClosedPeriod from '@/pages/ClosedPeriods/AddClosedPeriod'
import CalculationChanges from '@/pages/Log/Factors/CalculationChanges'
import EmissionFactors from '@/pages/Log/Factors/EmissionFactors'
import FactorRequests from '@/pages/Log/Factors/FactorRequests/RequestsOverview'
import LocationLogs from '@/pages/Log/LocationLogs'
import ProductDetails from '@/pages/Log/ProductDetails'
import ProductsCarbonFootPrint from '@/pages/Log/ProductsCarbonFootPrint'
import QuantitiesLogs from '@/pages/Log/Quantities'
import SupplierOnboarding from '@/pages/Onboarding/Supplier'
import OrganizationBase from '@/pages/Organization'
import BusinessUnits from '@/pages/Organization/BusinessUnits'
import Locations from '@/pages/Organization/Locations'
import Revenue from '@/pages/Organization/Revenue'

import BusinessUnitCrumb from '@/molecules/Breadcrumbs/BusinessUnitCrumb'
import CustomReportCrumb from '@/molecules/Breadcrumbs/CustomReportCrumbs'
import EditLogCrumb from '@/molecules/Breadcrumbs/EditLogCrumb'
import {
  SupplierBulkDetailsCrumb,
  SupplierRequestCrumb,
} from '@/molecules/Breadcrumbs/FactorRequestCrumbs'
import LocationCrumb from '@/molecules/Breadcrumbs/LocationCrumb'

import FactorFilterProvider from '@/contexts/factorFilter'
import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'
import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'

import AdminSettingsRoute from '../AdminSettingsRoute'
import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const SupplierBulkRequestDetails = lazy(
  () =>
    import(
      '@/pages/Log/BulkImport/SupplierBulkRequest/SupplierBulkRequestDetails/SupplierBulkRequestDetails'
    ),
)
const SupplierBulkRequestOverview = lazy(
  () =>
    import(
      '@/pages/Log/BulkImport/SupplierBulkRequest/SupplierBulkRequestOverview/SupplierBulkRequestsOverview'
    ),
)
const CcfBulkImport = lazy(() => import('@/pages/Log/BulkImport/CcfBulkImport'))
const CcfBulkImportDetails = lazy(
  () => import('@/pages/Log/BulkImport/CcfBulkImport/CcfBulkImportDetails'),
)
const CreateBulkImport = lazy(() => import('@/pages/Log/BulkImport/CreateBulkImport'))
const PcfBulkImports = lazy(() => import('@/pages/Log/BulkImport/PcfBulkImports'))
const PcfBulkImportDetails = lazy(
  () => import('@/pages/Log/BulkImport/PcfBulkImports/PcfBulkImportDetails'),
)
const UpsertBusinessUnit = lazy(() => import('@/pages/Organization/UpsertBusinessUnit'))
const UpsertLocation = lazy(() => import('@/pages/Organization/UpsertLocation'))
const Suppliers = lazy(() => import('@/pages/Organization/Suppliers'))
const Customers = lazy(() => import('@/pages/Organization/Customers'))
const EditLog = lazy(() => import('@/pages/Log/EditLog'))
const Factors = lazy(() => import('@/pages/Log/Factors'))
const Tags = lazy(() => import('@/pages/Log/Tags'))
const Organigram = lazy(() => import('@/pages/Organization/Organigram/Organigram'))
const CustomReport = lazy(() => import('@/pages/Share/Reports/CustomReport'))

const IncomingRequests = lazy(
  () => import('@/pages/Log/Factors/FactorRequests/pages/IncomingRequests'),
)
const OutgoingRequests = lazy(
  () => import('@/pages/Log/Factors/FactorRequests/pages/OutgoingRequests'),
)

const RedirectLocationLogs = (): ReactElement => {
  const { locationId } = useParams()

  return (
    <Navigate
      to={{ pathname: routes.organization.locations.logs.replace(':locationId', locationId || '') }}
    />
  )
}

const RedirectRequestsPage = (): ReactElement => {
  const orgIsSupplier = useOrgIsSupplier()
  return (
    <Navigate
      to={{
        pathname: orgIsSupplier
          ? routes.log.factors.requestsPage.incomingRequest
          : routes.log.factors.requestsPage.outgoingRequest,
      }}
    />
  )
}

const Wrapper = ({
  feature = 'log',
  children,
  excludeRoles,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature} excludeRoles={excludeRoles}>
        {children}
      </FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const getLogRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.log.base,
    props: { preventNavigation: true },
    children: [
      {
        path: routes.organization.base,
        element: (
          <FiltersProvider>
            <OrganizationBase />
          </FiltersProvider>
        ),
        children: [
          {
            index: true,
            props: { preventNavigation: true },
            element: (
              <Wrapper>
                <FiltersProvider>
                  <Locations />
                </FiltersProvider>
              </Wrapper>
            ),
            breadcrumb: t('layout.organization'),
          },
          {
            path: routes.organization.locations.base,
            element: (
              <Wrapper>
                <FiltersProvider>
                  <Locations />
                </FiltersProvider>
              </Wrapper>
            ),
            breadcrumb: t('layout.locations'),
          },
          {
            path: routes.organization.organigram,
            element: (
              <Wrapper>
                <Suspense fallback={<SuspenseSpinner />}>
                  <Organigram />
                </Suspense>
              </Wrapper>
            ),
            breadcrumb: t('layout.organigram'),
          },
          {
            path: routes.organization.businessUnits,
            element: (
              <Wrapper>
                <FiltersProvider>
                  <BusinessUnits />
                </FiltersProvider>
              </Wrapper>
            ),
            breadcrumb: t('layout.business-units'),
          },
          {
            path: routes.organization.revenue,
            element: (
              <Wrapper>
                <Revenue />
              </Wrapper>
            ),
            breadcrumb: t('layout.revenue'),
          },
        ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
      },
      {
        path: routes.organization.addBusinessUnit,
        element: (
          <Wrapper>
            <FiltersProvider>
              <AdminSettingsRoute level="manager">
                <Suspense fallback={<SuspenseSpinner />}>
                  <UpsertBusinessUnit />
                </Suspense>
              </AdminSettingsRoute>
            </FiltersProvider>
          </Wrapper>
        ),
      },
      {
        path: routes.organization.editBusinessUnit,
        element: (
          <Wrapper>
            <FiltersProvider>
              <AdminSettingsRoute level="manager">
                <Suspense fallback={<SuspenseSpinner />}>
                  <UpsertBusinessUnit />
                </Suspense>
              </AdminSettingsRoute>
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: BusinessUnitCrumb,
      },
      {
        path: routes.organization.addLocation,
        element: (
          <Wrapper>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <UpsertLocation />
              </Suspense>
            </AdminSettingsRoute>
          </Wrapper>
        ),
      },
      {
        path: routes.organization.editLocation,
        element: (
          <Wrapper>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <UpsertLocation />
              </Suspense>
            </AdminSettingsRoute>
          </Wrapper>
        ),
        breadcrumb: LocationCrumb,
      },
      {
        path: routes.organization.locations.singleLocation,
        element: <RedirectLocationLogs />,
      },
      {
        path: routes.log.tags,
        element: (
          <Wrapper excludeRoles={['viewer']}>
            <Suspense fallback={<SuspenseSpinner />}>
              <Tags />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('layout.tags'),
      },
      {
        path: routes.organization.locations.logs,
        element: (
          <Wrapper>
            <FiltersProvider>
              <LocationLogs />
            </FiltersProvider>
          </Wrapper>
        ),
      },
      {
        path: routes.organization.locations.editLog,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <EditLog />
            </Suspense>
          </Wrapper>
        ),
      },
      {
        path: routes.log.closedPeriods.customReport,
        element: (
          <Wrapper>
            <FiltersProvider saveQueryString={false}>
              <CustomReport />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: CustomReportCrumb,
      },
      {
        path: routes.log.corporateFootprint.base,
        exact: true,
        element: (
          <Wrapper>
            <FiltersProvider>
              <LocationLogs />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('layout.corporate-footprint'),
      },
      {
        path: routes.log.corporateFootprint.bulkImport.base,
        element: (
          <Wrapper>
            <CcfBulkImport />
          </Wrapper>
        ),
        breadcrumb: t('log.bulkImport'),
      },
      {
        path: routes.log.corporateFootprint.bulkImport.details,
        element: (
          <Wrapper>
            <CcfBulkImportDetails />
          </Wrapper>
        ),
      },
      {
        path: routes.log.corporateFootprint.bulkImport.create,
        element: (
          <Wrapper>
            <CreateBulkImport />
          </Wrapper>
        ),
        breadcrumb: t('actions.bulk-import.create'),
      },
      {
        path: routes.log.corporateFootprint.bulkImport.list,
        element: (
          <Wrapper>
            <CreateBulkImport />
          </Wrapper>
        ),
        breadcrumb: t('actions.bulk-import.past-imports'),
      },
      {
        path: routes.log.corporateFootprint.quantities,
        element: (
          <Wrapper>
            <FiltersProvider>
              <QuantitiesLogs />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.base,
        element: (
          <Wrapper>
            <FiltersProvider>
              <ProductsCarbonFootPrint />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('layout.product-footprint'),
      },
      {
        path: routes.log.corporateFootprint.edit,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <EditLog />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: EditLogCrumb,
      },
      {
        path: routes.log.products.edit,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <EditLog />
            </Suspense>
          </Wrapper>
        ),
      },
      {
        path: routes.log.products.bulkImports,
        element: (
          <Wrapper feature="pcf-bulk-import">
            <PcfBulkImports />
          </Wrapper>
        ),
        breadcrumb: t('log.bulkImport'),
      },
      {
        path: routes.log.products.productDetails,
        element: (
          <Wrapper>
            <FiltersProvider>
              <ProductDetails />
            </FiltersProvider>
          </Wrapper>
        ),
      },
      {
        path: routes.log.factors.base,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <Factors />
            </Suspense>
          </Wrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <Wrapper>
                <Navigate to={{ pathname: routes.log.factors.requestsPage.base }} />
              </Wrapper>
            ),
          },
          {
            path: routes.log.factors.emissions,
            element: (
              <Wrapper>
                <FiltersProvider>
                  <FactorFilterProvider>
                    <Suspense fallback={<SuspenseSpinner />}>
                      <EmissionFactors />
                    </Suspense>
                  </FactorFilterProvider>
                </FiltersProvider>
              </Wrapper>
            ),
          },
          {
            path: routes.log.factors.calculations.base,
            element: (
              <Wrapper>
                <Suspense fallback={<SuspenseSpinner />}>
                  <CalculationChanges />
                </Suspense>
              </Wrapper>
            ),
          },
          {
            path: routes.log.factors.requestsPage.base,
            element: (
              <Wrapper feature="custom-factors">
                <Suspense fallback={<SuspenseSpinner />}>
                  <FactorRequests />
                </Suspense>
              </Wrapper>
            ),
            children: [
              {
                index: true,
                element: (
                  <Wrapper>
                    <RedirectRequestsPage />
                  </Wrapper>
                ),
              },
              {
                path: routes.log.factors.requestsPage.outgoingRequest,
                element: (
                  <Wrapper feature="custom-factors">
                    <Suspense fallback={<SuspenseSpinner />}>
                      <OutgoingRequests />
                    </Suspense>
                  </Wrapper>
                ),
                breadcrumb: t('customer-requests.outgoing-request'),
              },
              {
                path: routes.log.factors.requestsPage.incomingRequest,
                element: (
                  <Wrapper feature="custom-factors">
                    <Suspense fallback={<SuspenseSpinner />}>
                      <IncomingRequests />
                    </Suspense>
                  </Wrapper>
                ),
                breadcrumb: t('customer-requests.incoming-request'),
              },
            ],
          },
        ],
        breadcrumb: t('layout.factors-breadcrumb'),
      },
      {
        path: routes.log.factors.bulkRequest.base,
        element: (
          <Wrapper>
            <SupplierBulkRequestOverview />
          </Wrapper>
        ),
      },
      {
        path: routes.log.factors.bulkRequest.details,
        element: (
          <Wrapper>
            <SupplierBulkRequestDetails />
          </Wrapper>
        ),
        breadcrumb: SupplierBulkDetailsCrumb,
      },
      {
        path: routes.log.factors.requestsPage.details,
        element: (
          <Wrapper feature="custom-factors">
            <Suspense fallback={<SuspenseSpinner />}>
              <SupplierOnboarding />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: SupplierRequestCrumb,
      },
      {
        path: routes.log.closedPeriods.base,
        element: (
          <Wrapper feature="log">
            <ClosedPeriods />
          </Wrapper>
        ),
        breadcrumb: t('layout.closed-periods'),
      },
      {
        path: routes.log.closedPeriods.addClosedPeriod,
        element: (
          <Wrapper feature="log">
            <FiltersProvider>
              <AddClosedPeriod />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('actions.create'),
      },
      {
        path: routes.log.closedPeriods.editClosedPeriod,
        element: (
          <Wrapper feature="log">
            <FiltersProvider>
              <AddClosedPeriod />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('customer.edit-btn'),
      },
      {
        path: routes.log.suppliers,
        element: (
          <Wrapper excludeRoles={['viewer']}>
            <Suspense fallback={<SuspenseSpinner />}>
              <Suppliers />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('layout.suppliers'),
      },
      {
        path: routes.log.customers,
        element: (
          <Wrapper excludeRoles={['viewer']}>
            <Suspense fallback={<SuspenseSpinner />}>
              <Customers />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('layout.customers'),
      },
      {
        path: routes.organization.addLocation,
        element: (
          <Wrapper>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <UpsertLocation />
              </Suspense>
            </AdminSettingsRoute>
          </Wrapper>
        ),
      },
      {
        path: routes.organization.editLocation,
        element: (
          <Wrapper>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <UpsertLocation />
              </Suspense>
            </AdminSettingsRoute>
          </Wrapper>
        ),
      },
      {
        path: routes.organization.addBusinessUnit,
        element: (
          <Wrapper>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <UpsertBusinessUnit />
              </Suspense>
            </AdminSettingsRoute>
          </Wrapper>
        ),
      },
      {
        path: routes.organization.editBusinessUnit,
        element: (
          <Wrapper>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <UpsertBusinessUnit />
              </Suspense>
            </AdminSettingsRoute>
          </Wrapper>
        ),
      },
      {
        path: routes.log.products.bulkImportDetails,
        element: (
          <Wrapper feature="pcf-bulk-import">
            <PcfBulkImportDetails />
          </Wrapper>
        ),
      },
      {
        path: `${routes.log.base}/*`,
        element: (
          <Wrapper>
            <NotFoundPage />
          </Wrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getLogRoutes
