import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineEyeOff, HiOutlineViewBoards } from 'react-icons/hi'

import { Checkbox, Col, Dropdown, Row } from 'antd/es'

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import classnames from 'classnames'

import {
  LogEntriesTableColumnKey,
  LogEntriesTableSortingKey,
  SortingDirection,
} from '@cozero/models'

import { useColumnManager } from '@/pages/Log/LocationLogs/hooks/useColumnManager'
import { ColumnSorting } from '@/pages/Log/LocationLogs/hooks/useSorting'

import classes from './TableHeaderCellPopover.module.less'

export const TableHeaderCellPopover = ({
  columnKey,
  title,
  sortable,
  sorting,
  setSorting,
  manageColumns,
  hideColumn,
}: {
  columnKey: LogEntriesTableColumnKey
  title: string | ReactElement
  sortable?: boolean
  sorting?: ColumnSorting
  setSorting: (sorting: ColumnSorting) => void
  manageColumns: () => void
  hideColumn: (key: LogEntriesTableColumnKey) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const columnManager = useColumnManager()
  const sortingKey = sortable ? columnKey : undefined

  const saveAppliedSorting = useCallback(
    (direction: SortingDirection): void => {
      if (
        (sorting?.direction === direction && String(sorting?.key) === String(sortingKey)) ||
        !sortingKey
      ) {
        setSorting(null)
      } else {
        setSorting({ key: sortingKey as unknown as LogEntriesTableSortingKey, direction })
      }
    },
    [sorting, sortingKey, setSorting],
  )

  const items = React.useMemo(
    () => [
      ...(sortingKey
        ? [
            {
              key: 'sort-ascending',
              icon: <SortingCaret />,
              label: (
                <Row>
                  <Col flex={1}>{t('log.log-entries-overview.popover.sort-ascending')}</Col>
                  <Col>
                    <Checkbox
                      checked={
                        String(sorting?.key) === String(sortingKey) &&
                        sorting?.direction === SortingDirection.ascending
                      }
                    />
                  </Col>
                </Row>
              ),
              onClick: () => saveAppliedSorting(SortingDirection.ascending),
              className: classes.sortingItem,
            },
            {
              key: 'sort-descending',
              icon: <SortingCaret />,
              label: (
                <Row>
                  <Col flex={1}>{t('log.log-entries-overview.popover.sort-descending')} </Col>
                  <Checkbox
                    checked={
                      String(sorting?.key) === String(sortingKey) &&
                      sorting?.direction === SortingDirection.descending
                    }
                  />
                </Row>
              ),
              onClick: () => saveAppliedSorting(SortingDirection.descending),
              className: classes.sortingItem,
            },
          ]
        : []),
      ...(!['organizationEmissions', 'logId'].includes(columnKey)
        ? [
            {
              key: 'hide-column',
              icon: <HiOutlineEyeOff />,
              label: t('log.log-entries-overview.popover.hide-this-column'),
              className: classes.sortingItem,
              onClick: () => hideColumn(columnKey),
            },
          ]
        : []),
      {
        key: 'manage-columns',
        icon: <HiOutlineViewBoards />,
        label: t('log.log-entries-overview.popover.manage-all-columns'),
        className: classes.sortingItem,
        onClick: manageColumns,
      },
    ],
    [
      hideColumn,
      columnKey,
      manageColumns,
      saveAppliedSorting,
      sorting?.direction,
      sorting?.key,
      sortingKey,
      t,
    ],
  )

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        menu={{ items }}
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
        overlayClassName={classes.dropdown}
      >
        <div className={classes.title}>
          <span>{title}</span>
          {sorting?.direction && String(sorting?.key) === String(sortingKey) && (
            <SortingCaret sorted={sorting?.direction} />
          )}
        </div>
      </Dropdown>
      {columnManager.modal}
    </>
  )
}

const SortingCaret = ({ sorted }: { sorted?: SortingDirection }): JSX.Element => {
  return (
    <span className={classnames(classes.sortIcon, !!sorted && classes.gray)}>
      <CaretUpFilled
        className={classnames(
          classes.squeezeIcon,
          sorted === SortingDirection.ascending ? classes.blueIcon : undefined,
        )}
      />
      <CaretDownFilled
        className={sorted === SortingDirection.descending ? classes.blueIcon : undefined}
      />
    </span>
  )
}
