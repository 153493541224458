// API Tags
export const TAG_USER_UPDATES = 'USER_UPDATES'
export const TAG_COUNT_UNREAD = 'COUNT_UNREAD'
export default [TAG_USER_UPDATES, TAG_COUNT_UNREAD]

// API slice endpoints
export const GET_USER_UPDATE = 'getUserUpdate'
export const GET_USER_UPDATES = 'getUserUpdates'
export const GET_USER_UPDATES_UNREAD = 'getUserUpdatesUnread'
export const COUNT_UNREAD = 'countUnread'
export const UPDATE_USER_UPDATE = 'updateUserUpdate'
export const READ_USER_UPDATES = 'readUserUpdates'
export const DELETE_USER_UPDATE = 'deleteUserUpdate'
