/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
} from 'react-icons/hi'

import { message as AntdMessage } from 'antd/es'
import { ArgsProps } from 'antd/lib/message'

import { COZERO_BLUE_80, COZERO_GREEN_60, COZERO_ORANGE_60 } from '@/styles/variables'

import classes from './Messages.module.less'

const success = (
  options: string | ArgsProps,
  duration?: Parameters<typeof AntdMessage.success>[1],
  onClose?: Parameters<typeof AntdMessage.success>[2],
) =>
  AntdMessage.success(
    {
      className: classes.success,
      icon: <HiOutlineCheckCircle size={18} color={COZERO_GREEN_60} />,
      ...(typeof options === 'string' ? { content: options } : options),
    },
    duration,
    onClose,
  )

const info = (
  options: string | ArgsProps,
  duration?: Parameters<typeof AntdMessage.success>[1],
  onClose?: Parameters<typeof AntdMessage.success>[2],
) =>
  AntdMessage.success(
    {
      className: classes.info,
      icon: <HiOutlineInformationCircle size={18} color={COZERO_BLUE_80} />,
      ...(typeof options === 'string' ? { content: options } : options),
    },
    duration,
    onClose,
  )

const error = (
  options: string | ArgsProps,
  duration?: Parameters<typeof AntdMessage.success>[1],
  onClose?: Parameters<typeof AntdMessage.success>[2],
) =>
  AntdMessage.error(
    {
      className: classes.error,
      icon: <HiOutlineExclamationCircle size={18} color={COZERO_ORANGE_60} />,
      ...(typeof options === 'string' ? { content: options } : options),
    },
    duration,
    onClose,
  )

export const message = {
  success,
  info,
  error,
}
