/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { TFunction } from 'react-i18next'
import { HiOutlineDuplicate } from 'react-icons/hi'

import { RowProps } from 'antd'
import { Button, Col, Image, Row } from 'antd/es'

import Icon from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'
import { User } from '@cozero/models'
import { userFullNameOrEmail } from '@cozero/utils'

import Avatar from '@/atoms/Avatar'
import Tag, { TagType } from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import i18n from '@/i18n'
import { copyToClipboard } from '@/utils/clipboard'
import { prettifyDate } from '@/utils/date'
import { formatCompact, formatNumber } from '@/utils/number'

import classes from './table-cell-renders.module.less'

type TranslationFn = TFunction<'common', undefined>

export const renderId = (id: string, t: TranslationFn): React.ReactNode => (
  <Row wrap={false} align="middle" justify="start">
    <Col style={{ display: 'flex', direction: 'rtl' }}>
      <Tag size="sm">
        <Tooltip content={id} align={{ offset: [0, -4] }}>
          {/* We limit the width oft the text so it only displays 3 chars */}
          {/* Doing this instead of stripping the text in js, allow the user to search for the full id in the page and have a match */}
          <Text className={classes.cellText} style={{ width: 45 }}>
            {/* We are adding a few non breakable sapce to make sure the id is long enough to display 3 chars before overflowing */}
            {/* If it only has 4 chars, then on Firefox it oveflows after 2 chars */}
            {id}&nbsp;&nbsp;&nbsp;&nbsp;
          </Text>
        </Tooltip>
      </Tag>
    </Col>

    <Col>
      <Button
        type="text"
        icon={<Icon component={() => <HiOutlineDuplicate />} />}
        onClick={(event) => {
          // Prevent the event from triggering the row navigation
          event.preventDefault()
          event.stopPropagation()

          copyToClipboard(id, t)
        }}
      />
    </Col>
  </Row>
)

export const renderTags = ({
  tags,
  type,
  classNames = '',
  showHyphenWhenEmpty = true,
  align = 'start',
}: {
  tags: (JSX.Element | number | string)[]
  type?: TagType
  classNames?: string
  showHyphenWhenEmpty?: boolean
  align?: RowProps['justify']
}): React.ReactElement => (
  <Row wrap={false} gutter={16} align="middle" justify={align}>
    {tags.map((tag, index) => {
      if (!tag && !showHyphenWhenEmpty) {
        return null
      }

      return (
        <Col key={index} className={(classes.defaultTag, classNames)}>
          <Tag size="sm" type={type} className={classes.defaultTag}>
            <Tooltip content={tag} align={{ offset: [0, -4] }}>
              {tag ?? '-'}
            </Tooltip>
          </Tag>
        </Col>
      )
    })}
  </Row>
)

export const renderDate = (
  date: string,
  { showTime, useLocalTime }: { showTime?: boolean; useLocalTime?: boolean } = {},
): React.ReactNode => (
  <Tag size="sm" className={classes.cellText}>
    <Tooltip
      content={prettifyDate(date, `MMM DD, YYYY${showTime ? ', h:mm a' : ''}`, { useLocalTime })}
      align={{ offset: [0, -4] }}
    >
      {prettifyDate(date, 'MMM DD, YYYY ', { useLocalTime })}
    </Tooltip>
  </Tag>
)

export const renderUser = (
  user: Omit<Partial<User>, 'photo'> & { photo?: Partial<User['photo']> },
): React.ReactNode => (
  <Row wrap={false} align="middle" gutter={6}>
    <Col>
      <Avatar
        image={user.photo}
        firstName={user.firstName}
        lastName={user.lastName}
        size="xs"
        showTooltip={false}
      />
    </Col>

    <Col flex={1} style={{ display: 'flex', minWidth: 0 }}>
      <Text className={classes.cellText}>
        <Tooltip
          content={userFullNameOrEmail(user.email as string, user.firstName, user.lastName)}
          align={{ offset: [0, -4] }}
        >
          {userFullNameOrEmail(user.email as string, user.firstName, user.lastName)}
        </Tooltip>
      </Text>
    </Col>
  </Row>
)

export const renderText = (text: string, width?: number): React.ReactNode => (
  <Tooltip content={text} align={{ offset: [0, -4] }}>
    <Text className={classes.cellText} style={{ width }} ellipsis>
      {text}
    </Text>
  </Tooltip>
)

export const renderCategory = (
  category?: NonNullable<OrganizationCarbonFootprintSearchDto['category']>,
): React.ReactNode => (
  <Row wrap={false} gutter={10} align="middle">
    {category?.image?.url && (
      <Col>
        <Image width={30} src={category.image.url} preview={false} />
      </Col>
    )}

    <Col flex={1} style={{ display: 'flex', alignItems: 'center' }}>
      <Text className={classes.cellText} ellipsis>
        <Tooltip content={category?.name as string} align={{ offset: [0, -4] }}>
          {category?.name as string}
        </Tooltip>
      </Text>
    </Col>
  </Row>
)

export const renderEmissions = (
  value: number,
  options: { bold?: boolean; ghost?: boolean } = {},
): React.ReactNode => {
  const formatter = new Intl.NumberFormat(localStorage.getItem('i18nextLng') ?? i18n.language, {
    style: 'decimal',
    maximumFractionDigits: 10,
    minimumFractionDigits: 2,
  })

  return (
    <Row wrap={false} justify="end">
      <Col>
        <Text
          className={classNames(classes.cellText, {
            [classes.bold]: options.bold,
            [classes.ghost]: value === null || options.ghost,
          })}
          ellipsis
        >
          {value === null ? (
            '--'
          ) : (
            <Tooltip content={formatter.format(value)} align={{ offset: [0, -4] }}>
              {formatNumber(value)}
            </Tooltip>
          )}
        </Text>
      </Col>
    </Row>
  )
}

export const renderYear = (date: Date, align: 'start' | 'end' = 'end'): React.ReactNode => (
  <div
    className={classNames(classes.cellNumberWrapper, {
      [classes.justifyEnd]: align === 'end',
    })}
  >
    <Text className={classes.cellText} ellipsis>
      {moment(date).year()}
    </Text>
  </div>
)

export const renderNumber = (
  value: number,
  {
    align = 'start',
    suffix,
    ...opts
  }: Parameters<typeof formatCompact>[1] & {
    align?: 'start' | 'end'
    suffix?: JSX.Element | string
  },
): React.ReactElement => (
  <div
    className={classNames(classes.cellNumberWrapper, {
      [classes.justifyEnd]: align === 'end',
    })}
  >
    <Text className={classes.cellText} ellipsis>
      {formatCompact(value, opts)}
      {suffix}
    </Text>
  </div>
)
