import { TFunction } from 'react-i18next'

import { UserUpdate, UserUpdateStatus, UserUpdateType } from '@prisma/client'
import moment from 'moment'

import { UserUpdateResponseDto } from '@cozero/dtos'
import { UserUpdatesMilestoneContext, UserUpdatesTaskContext } from '@cozero/models'

import { useAppDispatch } from '@/redux'
import api from '@/redux/api'
import { COUNT } from '@/redux/types'
import { TAG_COUNT_UNREAD } from '@/redux/userUpdates'
import {
  USER_UPDATES_LIMIT,
  useLazyGetUserUpdatesQuery,
  useReadUserUpdatesMutation,
  useUpdateUserUpdateMutation,
} from '@/redux/userUpdates/api'

const DEADLINE_UPDATE_TYPES: UserUpdateType[] = [
  UserUpdateType.MILESTONE_DEADLINE_UPDATED,
  UserUpdateType.TASK_DEADLINE_UPDATED,
]

const UPDATE_TYPES: Record<'MILESTONE' | 'TASK', UserUpdateType[]> = {
  MILESTONE: [
    UserUpdateType.MILESTONE_SUPPORTER_ASSIGNED,
    UserUpdateType.MILESTONE_TASKS_UPDATED,
    UserUpdateType.MILESTONE_DEADLINE_UPDATED,
    UserUpdateType.MILESTONE_OWNER_ASSIGNED,
    UserUpdateType.MILESTONE_DELETED,
    UserUpdateType.MILESTONE_COMPLETED,
  ],
  TASK: [
    UserUpdateType.TASK_OWNER_ASSIGNED,
    UserUpdateType.TASK_DEADLINE_UPDATED,
    UserUpdateType.TASK_DELETED,
    UserUpdateType.TASK_STATUS_UPDATED,
  ],
}

export const getEntityName = (update: UserUpdate): string => {
  if (UPDATE_TYPES.MILESTONE.includes(update.type)) {
    const context = update.context as unknown as UserUpdatesMilestoneContext
    return context.milestoneName
  }
  if (UPDATE_TYPES.TASK.includes(update.type)) {
    const context = update.context as unknown as UserUpdatesTaskContext
    return context.taskName
  }
  return ''
}

export const getAction = (
  type: UserUpdateType,
  t: TFunction,
  context: UserUpdateResponseDto['context'],
  doerName: string,
): Record<string, string> & { key: string } => {
  const taskContext = context as UserUpdatesTaskContext
  const milestoneContext = context as UserUpdatesMilestoneContext
  let key = `dashboard.home.all-updates.${type.toLowerCase().replace(/_/g, '-')}`
  if (DEADLINE_UPDATE_TYPES.includes(type)) {
    if (!context.previousDeadline) {
      key = `${key}-no-previous`
    } else if (!context.deadline) {
      key = `${key}-no-new`
    }
  }

  return {
    key,
    doerName,
    milestoneName: milestoneContext.milestoneName,
    taskName: taskContext.taskName,
    oldDeadline: moment(context.previousDeadline).format('ll'),
    newDeadline: moment(context.deadline).format('ll'),
    oldStatus: taskContext.previousStatus
      ? t(`dashboard.home.home.tasks.statuses.${taskContext.previousStatus}`)
      : '',
    newStatus: taskContext.taskStatus
      ? t(`dashboard.home.home.tasks.statuses.${taskContext.taskStatus}`)
      : '',
  }
}

type UseUpdatesDrawerReturn = {
  userUpdatesData: UserUpdateResponseDto[]
  userUpdatesTotal: number
  isLoading: boolean
  isFetching: boolean
  fetchUserUpdates: (page?: number, limit?: number, onlyUnread?: boolean) => Promise<void>
  handleClose: () => void
  markAllAsRead: (page: number, selectedFilter: 'ALL' | 'UNREAD') => Promise<void>
  markAsRead: (id: number, page: number, selectedFilter: 'ALL' | 'UNREAD') => Promise<void>
}

export const useUpdatesDrawer = ({ onClose }: { onClose?: () => void }): UseUpdatesDrawerReturn => {
  const dispatch = useAppDispatch()

  const [
    getUserUpdates,
    { isLoading: isLoadingUpdates, isFetching: isFetchingUpdates, data: userUpdates },
  ] = useLazyGetUserUpdatesQuery()
  const [readAll] = useReadUserUpdatesMutation()
  const [updateUserUpdate] = useUpdateUserUpdateMutation()

  // Fetch users for each update and set them in userUpdateItems
  const fetchUserUpdates = async (
    page = 1,
    limit = USER_UPDATES_LIMIT,
    onlyUnread = false,
  ): Promise<void> => {
    if (page === 1 || (userUpdates?.total && userUpdates?.data.length < userUpdates.total)) {
      await getUserUpdates({ page, limit, onlyUnread })
    }
  }

  const handleClose = async (): Promise<void> => {
    if (onClose) {
      dispatch(api.util.invalidateTags([{ type: TAG_COUNT_UNREAD, id: COUNT }]))
      onClose()
    }
  }

  const markAllAsRead = async (page: number, selectedFilter: 'ALL' | 'UNREAD'): Promise<void> => {
    const unreadUpdates = userUpdates?.data.filter(
      (item) => item.status === UserUpdateStatus.UNREAD,
    )

    if (unreadUpdates?.length) {
      await readAll()
      // Fetch updates for all the pages until the update is located
      await getUserUpdates({
        page: 1,
        limit: page * USER_UPDATES_LIMIT,
        onlyUnread: selectedFilter === 'UNREAD',
      })
    }
  }

  const markAsRead = async (
    id: number,
    page: number,
    selectedFilter: 'ALL' | 'UNREAD',
  ): Promise<void> => {
    await updateUserUpdate({
      id,
      status: UserUpdateStatus.READ,
    })

    // Fetch updates for all the pages until the update is located
    await getUserUpdates({
      page: 1,
      limit: page * USER_UPDATES_LIMIT,
      onlyUnread: selectedFilter === 'UNREAD',
    })
  }

  return {
    userUpdatesData: userUpdates?.data || [],
    userUpdatesTotal: userUpdates?.total || 0,
    isLoading: isLoadingUpdates,
    isFetching: isFetchingUpdates,
    fetchUserUpdates,
    handleClose,
    markAllAsRead,
    markAsRead,
  }
}
