import React, { ReactElement, Suspense, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Col, Row } from 'antd/es'

import moment from 'moment'

import { routes } from '@cozero/utils'

import { AppErrorFallback } from '@/templates/ErrorFallback/AppErrorFallback'
import SuspenseSpinner from '@/templates/SuspenseSpinner'

import { NavSidebar } from '@/organisms/NavSidebar/NavSidebar'
import { UpgradeModal } from '@/organisms/UpgradeModal/UpgradeModal'

import { Breadcrumbs } from '@/molecules/Breadcrumbs'

import Alert from '@/atoms/Alert'
import Container from '@/atoms/Container'

import { useSubscriptionContext } from '@/contexts/subscription'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'
import { useScrollToTop } from '@/hooks/useScrollToTop'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUser, selectUserOrganization } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import ErrorBoundary from '../ErrorBoundary'

import classes from './styles.module.less'

const pagesWithNoBreadcrumbs = [routes.expiredToken, routes.dashboardHome.home]

const PageLayout = (): ReactElement => {
  const { t } = useTranslation()
  const { trackPageView } = useMixpanel()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)

  const organization = useAppSelector(selectUserOrganization)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const displayBreadCrumbs = !pagesWithNoBreadcrumbs.includes(location.pathname)

  const { scrollElementRef } = useScrollToTop()

  const orgIsSupplier = useOrgIsSupplier()

  const {
    getCalendarLink,
    setSubscribeModalSettings,
    subscribeModalSettings,
    subscriptionExpired,
  } = useSubscriptionContext()
  const trialPeriodDays = useMemo(() => {
    return moment(organization?.createdAt)
      .clone()
      .add(organization?.pricing?.daysOfTrialPeriod, 'days')
      .diff(moment(), 'days')
  }, [organization])
  const [bgColor, setBgColor] = useState('')

  const closeSubscribeModal = (): void => {
    setSubscribeModalSettings({ ...subscribeModalSettings, visible: false })
  }

  const goToSubscriptionPage = (): void => {
    closeSubscribeModal()
    return navigate(routes.settings.subscription)
  }

  useEffect(() => {
    if (location.pathname === routes.onboarding) {
      setBgColor('onboarding-blue')
    } else {
      setBgColor('')
    }

    trackPageView({ url: window.location.href })
  }, [location.pathname])

  useEffect(() => {
    if (subscriptionExpired && location.pathname !== routes.settings.plans) {
      return goToSubscriptionPage()
    }
  }, [subscriptionExpired, location.pathname])

  const TrialNotification = useMemo((): ReactElement | null => {
    if (organization?.pricing?.type !== 'free') {
      return null
    }
    return (
      <div className={classes.notification}>
        <span className={classes.title}>{t('layout.upgrade-cta.title')}</span>
        {trialPeriodDays > 0 ? (
          <span className={classes.subtitle}>
            {trialPeriodDays} {t('layout.days')}
          </span>
        ) : (
          <span className={classes.subtitle}>{t('layout.subscription-expired')}</span>
        )}
        {isManagerOrAdmin && <a href="mailto:support@cozero.io"> {t('contact')}</a>}
      </div>
    )
  }, [subscriptionExpired])

  useEffect(() => {
    if (subscriptionExpired && location.pathname !== routes.settings.plans) {
      return goToSubscriptionPage()
    }
  }, [subscriptionExpired, location.pathname])

  if (!user && localStorage.getItem('EXPIRED_TOKEN') !== 'true') {
    return <Navigate to={routes.logout} />
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={AppErrorFallback}>
        <section className={classes.mainLayout} data-cy="main-layout">
          <NavSidebar trialNotification={TrialNotification} supplierOnboarding={orgIsSupplier} />

          <ErrorBoundary FallbackComponent={AppErrorFallback}>
            <main
              ref={scrollElementRef}
              data-cy="content"
              className={`${classes.content} ${
                bgColor === 'onboarding-blue' && classes.onboardingBlue
              } ${location.pathname.split('/').includes('log') && classes.logPage}`}
            >
              {selectedBusinessUnit && !selectedBusinessUnit.active && (
                <Alert type="danger" message={t('layout.inactive-business-unit')} />
              )}
              {displayBreadCrumbs ? <Breadcrumbs /> : ''}
              <Row justify="center" className={classes.innerContent}>
                <Container>
                  <Col span={24}>
                    <Suspense fallback={<SuspenseSpinner />}>
                      <Outlet />
                    </Suspense>
                  </Col>
                </Container>
              </Row>
            </main>
          </ErrorBoundary>
        </section>
      </ErrorBoundary>
      <UpgradeModal
        {...subscribeModalSettings}
        handleCancel={closeSubscribeModal}
        getCalendarLink={getCalendarLink}
        goToSubscriptionPage={goToSubscriptionPage}
        organization={organization}
      />
    </>
  )
}

export default PageLayout
