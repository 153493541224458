import { UserUpdate, UserUpdateStatus } from '@prisma/client'

import { UserUpdateResponseDto } from '@cozero/dtos'
import { Page } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import { COUNT, LIST } from '../types'

import {
  COUNT_UNREAD,
  DELETE_USER_UPDATE,
  GET_USER_UPDATE,
  GET_USER_UPDATES,
  READ_USER_UPDATES,
  TAG_COUNT_UNREAD,
  TAG_USER_UPDATES,
  UPDATE_USER_UPDATE,
} from './constants'

export const USER_UPDATES_LIMIT = 20

const userUpdatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_USER_UPDATES]: builder.query<
      Page<UserUpdateResponseDto>,
      { page: number; limit: number; onlyUnread?: boolean }
    >({
      query: (
        { page, limit, onlyUnread } = { page: 1, limit: USER_UPDATES_LIMIT, onlyUnread: false },
      ) => ({
        url: centralApiGatewayClient.userUpdates.GET_MANY,
        method: 'GET',
        params: { page, limit, onlyUnread },
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const { onlyUnread } = queryArgs
        return `${endpointName}-${onlyUnread ? 'onlyUnread' : 'all'}`
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          currentCache.data = [...newItems.data]
        } else {
          const existingIds = new Set(currentCache.data.map((item) => item.id))
          const filteredNewItems = newItems.data.filter((item) => !existingIds.has(item.id))
          currentCache.data.push(...filteredNewItems)
        }
        currentCache.total = newItems.total
      },
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        providesList<UserUpdateResponseDto[], typeof TAG_USER_UPDATES>(
          result?.data,
          TAG_USER_UPDATES,
          LIST,
        ),
    }),

    [GET_USER_UPDATE]: builder.query<UserUpdate, number>({
      query: (id) => ({
        url: centralApiGatewayClient.userUpdates.GET_ONE.replace(':id', `${id}`),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_USER_UPDATES, id: result?.id }],
    }),

    [COUNT_UNREAD]: builder.query<{ count: number }, void>({
      query: () => ({
        url: centralApiGatewayClient.userUpdates.COUNT_UNREAD,
        method: 'GET',
      }),
      providesTags: () => [{ type: TAG_COUNT_UNREAD, id: COUNT }],
    }),

    [UPDATE_USER_UPDATE]: builder.mutation<UserUpdate, { id: number; status: UserUpdateStatus }>({
      query: ({ id, status }) => ({
        url: centralApiGatewayClient.userUpdates.UPDATE_ONE.replace(':id', `${id}`),
        method: 'PUT',
        data: { status },
      }),
    }),

    [READ_USER_UPDATES]: builder.mutation<{ count: number }, void>({
      query: () => ({
        url: centralApiGatewayClient.userUpdates.READ_ALL,
        method: 'PUT',
      }),
    }),

    [DELETE_USER_UPDATE]: builder.mutation<UserUpdate, number>({
      query: (id) => ({
        url: centralApiGatewayClient.userUpdates.DELETE_ONE.replace(':id', `${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: TAG_USER_UPDATES, id: result?.id }],
    }),
  }),
})

export const {
  useGetUserUpdatesQuery,
  useLazyGetUserUpdatesQuery,
  useGetUserUpdateQuery,
  useUpdateUserUpdateMutation,
  useDeleteUserUpdateMutation,
  useReadUserUpdatesMutation,
  useLazyCountUnreadQuery,
  useCountUnreadQuery,
} = userUpdatesApiSlice

export default userUpdatesApiSlice
