import { RootState } from '..'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'

import { OrganizationCarbonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

import { OrganizationEmissionsDateFilter, SelectedRows } from './constants'

export const selectOrganizationEmissionsFilters = (
  state: RootState,
): OrganizationCarbonFootprintFormValues => {
  return state.logEntriesOverview.filters
}

export const selectOrganizationEmissionsDateFilter = (
  state: RootState,
): OrganizationEmissionsDateFilter => {
  return state.logEntriesOverview.dateFilter
}

export const selectLastUpdateDate = (state: RootState): Date | undefined => {
  return state.logEntriesOverview.lastUpdateDate
}

export const selectOrganizationEmissionsSelectedRows = (state: RootState): SelectedRows => {
  return state.logEntriesOverview.selectedRows
}

export const selectCurrentPageResults = (
  state: RootState,
): OrganizationCarbonFootprintSearchDto[] | undefined => {
  return state.logEntriesOverview.currentPageResults
}
