import React, { HTMLAttributes, MouseEvent, ReactElement, memo } from 'react'

import classNames from 'classnames'

import { useMixpanel } from '@/hooks/useMixpanel'

import LoadingSpinner from '../LoadingSpinner'

import BtnIcon from './BtnIcon'
import classes from './Button.module.less'
import { ButtonProps, ButtonSize } from './types'

const Button = ({
  children,
  href,
  onClick,
  action,
  category,
  analyticsProps,
  type = 'secondary',
  className = '',
  loading = false,
  fillParent = false,
  size = 'md',
  htmlType = 'button',
  disabled = false,
  color,
  prefixIcon,
  suffixIcon,
  iconClass,
  ...rest
}: ButtonProps): ReactElement => {
  const rootClasses = classNames(className, classes.button, classes[type], classes[size], {
    [classes.loading]: loading,
    [classes[color || '']]: color && !disabled && !loading,
    [classes.fillParent]: fillParent,
    [classes.disabled]: disabled,
  })

  const prefixClasses = classNames(iconClass, { [classes.prefixWithChildren]: !!children })

  const { trackAction } = useMixpanel()

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    if (loading) {
      return
    }

    if (action && category) {
      trackAction(category, action, analyticsProps)
    }

    if (onClick) {
      return onClick(event)
    }
  }

  if (type === 'link') {
    return (
      <a href={href} className={rootClasses} {...(rest as HTMLAttributes<HTMLAnchorElement>)}>
        {prefixIcon && <BtnIcon icon={prefixIcon} className={prefixClasses} />}
        {children}
        {suffixIcon && <BtnIcon icon={suffixIcon} className={`${classes.suffix} ${iconClass}`} />}
      </a>
    )
  }

  return (
    <button
      onClick={onClickHandler}
      className={rootClasses}
      type={htmlType}
      disabled={disabled}
      {...(rest as HTMLAttributes<HTMLButtonElement>)}
    >
      {loading && (
        <div className={classes.loadingWrapper}>
          <LoadingSpinner
            size={size === 'sm' ? '2xs' : 'xs'}
            color={['secondary', 'ghost'].includes(type) ? 'dark' : 'white'}
          />
        </div>
      )}
      {!loading && prefixIcon && <BtnIcon icon={prefixIcon} className={prefixClasses} />}
      {children}
      {suffixIcon && <BtnIcon icon={suffixIcon} className={`${classes.suffix} ${iconClass}`} />}
    </button>
  )
}

export type { ButtonProps, ButtonSize }

export default memo(Button)
