/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { Col, Form, Row } from 'antd/es'

import moment, { Moment } from 'moment'

import { SearchSelect } from '@/molecules/FiltersDrawer/SearchSelect'

import DatePicker from '@/atoms/DatePicker'
import DateRangePicker from '@/atoms/DateRangePicker'

import { FilterDrawerFormProps } from './FilterDrawerForm'
import classes from './FilterDrawerForm.module.less'
import { LogicOperatorInput } from './inputs/LogicOperatorInput'
import { FilterValueType, FomInputType, FormInput } from './types'

export const FilterDrawerFormInput = <
  TSearchSelectFiltersValuesKey extends string,
  TSearchSelectStringFiltersValuesKey extends string,
  TSearchLogEntryDataQualitySelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TDateRangePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
  TSingleSelectFiltersValuesKey extends string,
>({
  value: formValue,
  input,
  onChange,
  onSearch,
  isLoading,
  options,
}: {
  input: FormInput<
    TSearchSelectFiltersValuesKey,
    TSearchSelectStringFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TDateRangePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey,
    TSingleSelectFiltersValuesKey
  >
  onChange: (value: FilterValueType | undefined) => void
} & Pick<
  FilterDrawerFormProps<
    TSearchSelectFiltersValuesKey,
    TSearchSelectStringFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDateRangePickerFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey,
    TSingleSelectFiltersValuesKey
  >,
  'value' | 'onSearch' | 'isLoading' | 'options'
>): React.ReactElement => {
  const { t } = useTranslation()

  const label = input.icon ? (
    <div className={classes.labelWithIcon}>
      {input.icon}
      <span>{input.label}</span>
    </div>
  ) : (
    input.label
  )

  const formatDate = useCallback((): [Moment | null, Moment | null] => {
    return [
      moment(formValue[input.key]?.[0]).isValid() ? moment(formValue[input.key]?.[0]) : null,
      moment(formValue[input.key]?.[1]).isValid() ? moment(formValue[input.key]?.[1]) : null,
    ]
  }, [formValue, input.key])

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label={label}>
          {[FomInputType.SEARCH_SELECT, FomInputType.SINGLE_SELECT].includes(input.type) && (
            <SearchSelect
              multipleAllowed={input.type === FomInputType.SEARCH_SELECT}
              allowClear={input.type !== FomInputType.SEARCH_SELECT}
              size="large"
              value={formValue[input.key]}
              optionFilterProp="label"
              placeholder={t('log.log-entries-drawer.select-and-search.placeholder')}
              onSearch={onSearch?.[input.key]}
              loading={isLoading?.[input.key]}
              options={options?.[input.key]}
              onChange={(value) => {
                if ((Array.isArray(value) && value.length) || value) {
                  return onChange(value)
                }
                onChange(undefined)
              }}
              preloadedOptionsMode={true}
            />
          )}
          {input.type === FomInputType.TEXT && (
            <Input
              className={classes.textInput}
              size="large"
              value={formValue[input.key]}
              placeholder={input.placeholder}
              onChange={(el) => {
                onChange(el.target.value)
              }}
            ></Input>
          )}
          {input.type === FomInputType.DATE_PICKER && (
            <DatePicker
              className={classes.datePickerInput}
              picker="date"
              value={formValue[input.key] ? moment(formValue[input.key]) : null}
              onChange={(value) => {
                onChange(value?.toDate() ?? null)
              }}
              allowClear={true}
              style={{ width: '100%' }}
              suffixIcon={null}
            />
          )}
          {input.type === FomInputType.DATE_PICKER_YEAR && (
            <DatePicker
              className={classes.datePickerInput}
              picker="year"
              value={formValue[input.key] ? moment(formValue[input.key]) : null}
              onChange={(value) => {
                onChange(value?.toDate() ?? null)
              }}
              allowClear={true}
              style={{ width: '100%' }}
              suffixIcon={null}
            />
          )}
          {input.type === FomInputType.RANGE_DATE_PICKER && (
            <DateRangePicker
              className={classes.datePickerInput}
              allowClear={true}
              style={{ width: '100%' }}
              value={
                formValue[input.key] && formValue[input.key]?.[0] && formValue[input.key]?.[1]
                  ? formatDate()
                  : undefined
              }
              onChange={(value) => {
                if (value.every((el) => !el)) {
                  return onChange(undefined)
                }
                const startDate = value[0]?.startOf('day').format('YYYY-MM-DD HH:mm:ss')
                const endDate = value[1]?.endOf('day').format('YYYY-MM-DD HH:mm:ss')
                return onChange([startDate, endDate])
              }}
            />
          )}
          {input.type === FomInputType.LOGIC_OPERATOR && (
            <LogicOperatorInput
              value={formValue[input.key]}
              onChange={(value) => {
                onChange(value)
              }}
              placeholder={t('log.log-entries-drawer.logical-operator-input.placeholder')}
              options={input.options}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}
