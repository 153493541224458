import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Cascader } from 'antd/es'
import { DefaultOptionType } from 'antd/es/cascader'

import { ActivityDataSourceWithChildren } from '@cozero/models'

import classes from './classes.module.less'

const flatten = (
  children: unknown[],
  extractChildren: (x: { children: unknown[] }) => unknown[],
): unknown => {
  return Array.prototype.concat.apply(
    children,
    children.map((x) =>
      flatten(extractChildren(x as unknown as { children: unknown[] }) || [], extractChildren),
    ),
  )
}

interface ActivityDataSourceDropdownProps {
  options?: ActivityDataSourceWithChildren[]
  setSelectedActivityDataSource?: (value?: ActivityDataSourceWithChildren) => void
  selectedActivityDataSource?: ActivityDataSourceWithChildren
  bordered?: boolean
  className?: string
  size?: 'small' | 'middle' | 'large'
  onBlur?: () => void
  defaultOpen?: boolean
  onChange?: (val: number[]) => void
  value?: number[]
  disabled?: boolean
  allowClear?: boolean
  placeholder?: string
  suffixIcon?: React.ReactNode
}

function ActivityDataSourceCascader({
  options: activityDataSources = [],
  setSelectedActivityDataSource,
  selectedActivityDataSource,
  bordered,
  className,
  size = 'large',
  onBlur,
  defaultOpen = false,
  allowClear = true,
  placeholder,
  onChange,
  value,
  disabled,
  suffixIcon,
}: ActivityDataSourceDropdownProps): JSX.Element {
  const { t } = useTranslation('common')
  const [options, setOptions] = useState<DefaultOptionType[]>([])
  const [cascaderValue, setCascaderValue] = useState<string[]>([])

  useEffect(() => {
    setCascaderValue(parseActivityDataSource(selectedActivityDataSource))
  }, [options, selectedActivityDataSource])

  const parseOption = (activityDataSource: ActivityDataSourceWithChildren): DefaultOptionType => {
    return {
      value: `${activityDataSource.id}`,
      children: activityDataSource.children
        ?.map((child) => parseOption(child as ActivityDataSourceWithChildren))
        .sort((a, b) =>
          a && b ? (a.label as string)?.trim().localeCompare((b.label as string)?.trim()) : 1,
        ),
      label: activityDataSource.name,
    }
  }

  const getTreeOfIds = (
    id: number,
    flattenedAds: ActivityDataSourceWithChildren[],
    result: number[] = [],
  ): number[] => {
    const ads = flattenedAds.find((x) => x.id === id)
    if (!ads?.id) {
      return result
    }
    result.push(ads.id)

    if (ads.parentId) {
      return getTreeOfIds(ads.parentId, flattenedAds, result)
    }
    return result.reverse()
  }

  useEffect(() => {
    if (value && activityDataSources?.length) {
      if (value.length < 2) {
        const extractChildren = (x: { children: unknown[] }): unknown[] => x.children
        const flattenedAds: ActivityDataSourceWithChildren[] = flatten(
          activityDataSources,
          extractChildren,
        ) as ActivityDataSourceWithChildren[]

        const ads = flattenedAds.find((x) => x.id?.toString() === value[0]?.toString())
        if (ads) {
          const ids = getTreeOfIds(ads.id, flattenedAds)
          setCascaderValue(parseActivityDataSource(ads))
          setSelectedActivityDataSource && setSelectedActivityDataSource(ads)
          onChange && onChange(ids.map(Number))
        }
      } else {
        const ads = getActivityDataSourceByIds(value)
        setCascaderValue(parseActivityDataSource(ads))
      }
    }
  }, [value?.toString(), activityDataSources, options])

  useEffect(() => {
    const newOptions = activityDataSources.map((activityDataSource) =>
      parseOption(activityDataSource),
    )
    setOptions([...newOptions])
  }, [activityDataSources])

  const selectActivityDataSource = (values: number[]): void => {
    if (!values?.length) {
      return
    }

    onChange && onChange(values.map(Number))
    const ads = getActivityDataSourceByIds(values)
    setSelectedActivityDataSource && setSelectedActivityDataSource(ads)
  }

  const getActivityDataSourceByIds = (
    values: number[],
  ): ActivityDataSourceWithChildren | undefined => {
    let parentDataSource: ActivityDataSourceWithChildren | undefined

    values?.forEach((value) => {
      parentDataSource = parentDataSource
        ? (parentDataSource.children?.find(
            (activityDataSource) => activityDataSource.id === Number(value),
          ) as ActivityDataSourceWithChildren)
        : activityDataSources.find((activityDataSource) => activityDataSource.id === Number(value))
    })

    return parentDataSource
  }

  const parseActivityDataSource = (
    activityDataSource?: ActivityDataSourceWithChildren | ActivityDataSourceWithChildren['parent'],
  ): string[] => {
    const adsWithParents = activityDataSource
      ? [
          ...(activityDataSource.parent
            ? parseActivityDataSource(
                activityDataSource.parent as ActivityDataSourceWithChildren['parent'],
              )
            : []),
          `${activityDataSource.id}`,
        ]
      : []
    if (options.find((obj) => adsWithParents.includes(obj.value as string))) {
      return adsWithParents
    }
    return []
  }
  return (
    <Cascader
      disabled={disabled}
      onDropdownVisibleChange={(visible) => (!visible && onBlur ? onBlur() : null)}
      popupClassName={classes.locationDropdown}
      showSearch
      style={{ width: '100%' }}
      size={size}
      onBlur={onBlur}
      allowClear={allowClear}
      placeholder={placeholder ?? t('activity-data-sources.select')}
      changeOnSelect={true}
      onPopupVisibleChange={(open) => (!open ? onBlur : undefined)}
      onChange={(values) => selectActivityDataSource(values as number[])}
      value={cascaderValue}
      bordered={bordered}
      className={className}
      options={options}
      defaultOpen={defaultOpen}
      data-cy="calculation-cascader"
      suffixIcon={suffixIcon}
    />
  )
}

export default ActivityDataSourceCascader
