export const TAG_COMPUTED_REPORT_TYPES = 'TAG_COMPUTED_REPORT_TYPES'
export const TAG_LATEST_COMPUTED_REPORT_DATA = 'TAG_LATEST_COMPUTED_REPORT_DATA'
export const TAG_MANY_COMPUTED_REPORTS = 'TAG_MANY_COMPUTED_REPORTS'
export const TAG_ONE_COMPUTED_REPORT = 'TAG_ONE_COMPUTED_REPORT'

export default [
  TAG_COMPUTED_REPORT_TYPES,
  TAG_LATEST_COMPUTED_REPORT_DATA,
  TAG_MANY_COMPUTED_REPORTS,
  TAG_ONE_COMPUTED_REPORT,
]
