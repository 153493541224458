import { createApi } from '@reduxjs/toolkit/query/react'

import { tagTypes } from '@/redux/api'
import { RtkBaseQuery } from '@/redux/axiosBaseQuery'
import { LIST, LIST_TYPE, PARTIAL_LIST_TYPE } from '@/redux/types'

/**
 *
 * Utility function for filling the providesTags property in endpoints in
 * api-slices in redux
 *
 * @param resultsWithIds - the returned data from the endpoint
 * @param tagType - the tag the data should be related to
 *
 * @returns The parsed array of resuslts, tagged by the given tag, id and a 'LIST' tuple
 * @see {@link  https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#abstracting-common-providesinvalidates-usage}
 **/
export const providesList = <R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
  listId: LIST_TYPE | PARTIAL_LIST_TYPE = LIST,
): { type: T; id?: string | number }[] => {
  return resultsWithIds
    ? [{ type: tagType, id: listId }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: listId }]
}

type MainApiSlice = typeof createApi<
  RtkBaseQuery,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any>,
  'api',
  (typeof tagTypes)[number]
>

export type RtkEndpointBuilderArg = Parameters<
  Parameters<ReturnType<MainApiSlice>['injectEndpoints']>[0]['endpoints']
>[0]
