import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { TFunction } from 'react-i18next'
import { Navigate } from 'react-router'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import {
  ComputedReportNameCrumb,
  ComputedReportTypeCrumb,
} from '@/molecules/Breadcrumbs/ComputedReportCrumb'

import AdminProvider from '@/contexts/admin'
import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const PageNotFound = lazy(() => import('@/pages/404'))
const AvailableReports = lazy(() => import('@/pages/Share/Reports/AvailableReports'))
const ComputedReport = lazy(() => import('@/pages/Share/Reports/ComputedReports/'))
const YourReports = lazy(() => import('@/pages/Share/Reports/YourReports'))
const ReportsPage = lazy(() => import('@/pages/Share/Reports/ReportsPage'))
const ViewReport = lazy(() => import('@/pages/Share/Reports/PresetReports/ViewReport'))
const BalanceSheetReport = lazy(
  () => import('@/pages/Share/Reports/PresetReports/BalanceSheetReport'),
)

const Wrapper = ({
  children,
  feature = 'share',
}: {
  children: ReactNode
  feature?: string
}): ReactElement => (
  <AdminProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
    </PrivateRoute>
  </AdminProvider>
)

/**
 * All routes in the /share/ namespace
 */
const getShareRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.share.base,
    element: (
      <Wrapper>
        <Navigate to={{ pathname: routes.share.reports.availableReports }} />
      </Wrapper>
    ),
  },
  {
    path: routes.share.reports.base,
    element: (
      <Wrapper feature="reports">
        <Suspense fallback={<SuspenseSpinner />}>
          <ReportsPage />
        </Suspense>
      </Wrapper>
    ),
    breadcrumb: t('layout.reports'),
    children: [
      {
        index: true,
        element: (
          <Wrapper>
            <Navigate to={{ pathname: routes.share.reports.availableReports }} />
          </Wrapper>
        ),
      },
      {
        path: routes.share.reports.yourReports,
        element: (
          <Wrapper feature="computed-reports">
            <Suspense fallback={<SuspenseSpinner />}>
              <YourReports />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('share.your-reports.tab-title'),
      },
      {
        path: routes.share.reports.availableReports,
        element: (
          <Wrapper feature="reports">
            <Suspense fallback={<SuspenseSpinner />}>
              <AvailableReports />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('share.available-reports.tab-title'),
      },
    ],
  },
  {
    path: routes.share.reports.computedReport.base,
    props: { preventNavigation: true },
    breadcrumb: t('reports.computed-reports'),
    children: [
      {
        path: routes.share.reports.computedReport.view,
        element: (
          <Wrapper feature="computed-reports">
            <ComputedReport />
          </Wrapper>
        ),
        breadcrumb: ComputedReportTypeCrumb,
      },
      {
        path: routes.share.reports.computedReport.details,
        element: (
          <Wrapper feature="computed-reports">
            <ComputedReport />
          </Wrapper>
        ),
        breadcrumb: ComputedReportNameCrumb,
      },
    ],
  } as BreadcrumbsRoute & { children: BreadcrumbsRoute[] },
  {
    path: routes.share.reports.viewBalanceSheetReport,
    element: (
      <Wrapper feature="reports">
        <LogProvider>
          <Suspense fallback={<SuspenseSpinner />}>
            <BalanceSheetReport />
          </Suspense>
        </LogProvider>
      </Wrapper>
    ),
    breadcrumb: t('reports.balanceSheetReport.title'),
  },
  {
    path: routes.share.reports.viewReport,
    element: (
      <Wrapper feature="reports">
        <LogProvider>
          <FiltersProvider>
            <Suspense fallback={<SuspenseSpinner />}>
              <ViewReport />
            </Suspense>
          </FiltersProvider>
        </LogProvider>
      </Wrapper>
    ),
  },
  {
    path: `${routes.share.base}/*`,
    element: (
      <Wrapper>
        <Suspense fallback={<SuspenseSpinner />}>
          <PageNotFound />
        </Suspense>
      </Wrapper>
    ),
  },
]
export default getShareRoutes
