import { InputProps } from 'antd/es'

import { AssessmentReport, LogEntryDataQuality } from '@prisma/client'

import { LogicOperatorInputValueDto } from '@cozero/dtos'

import { LogicOperatorOption } from './inputs/LogicOperatorInput'

export type FilterValueType =
  | number[]
  | string[]
  | string
  | Date
  | Date[]
  | number
  | null
  | LogicOperatorInputValueDto

// Define FilterValues as a combination of different filter value types
export type FilterValues<
  TSearchSelectFiltersValuesKey extends string,
  TSearchSelectStringFiltersValuesKey extends string,
  TSearchLogEntryDataQualitySelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TDateRangePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
  TSingleSelectFiltersValuesKey extends string,
> = Partial<
  SearchSelectFiltersValues<TSearchSelectFiltersValuesKey> &
    SearchLogEntryDataQualitySelectFiltersValues<TSearchLogEntryDataQualitySelectFiltersValuesKey> &
    DatePickerFiltersValues<TDatePickerFiltersValuesKey> &
    DateRangePickerFiltersValues<TDateRangePickerFiltersValuesKey> &
    TextFiltersValues<TTextFiltersValuesKey> &
    LogicOperatorFiltersValues<TLogicOperatorFiltersValuesKey> &
    SingleSelectFiltersValues<TSingleSelectFiltersValuesKey> &
    SearchSelectStringFiltersValue<TSearchSelectStringFiltersValuesKey>
>

/** Filters where the outcome is a multi-select that supports search while typing */
export type SearchSelectFiltersValues<TSearchSelectFiltersValuesKey extends string> = Record<
  TSearchSelectFiltersValuesKey,
  number[]
>

/** Filters where the outcome is a multi-select that supports search while typing */
export type SearchSelectStringFiltersValue<TSearchSelectStringFiltersValuesKey extends string> =
  Record<TSearchSelectStringFiltersValuesKey, string[]>

/** Filters where the outcome is a multi-select of LogEntryDataQuality that supports search while typing */
export type SearchLogEntryDataQualitySelectFiltersValues<
  TSearchSelectFiltersValuesKey extends string,
> = Record<TSearchSelectFiltersValuesKey, LogEntryDataQuality[]>

/** filters that outcome will be a date */
export type DatePickerFiltersValues<TDatePickerFiltersValuesKey extends string> = Record<
  TDatePickerFiltersValuesKey,
  Date
>

/** filters that outcome will be an array of dates */
export type DateRangePickerFiltersValues<TDateRangePickerFiltersValuesKey extends string> = Record<
  TDateRangePickerFiltersValuesKey,
  Date[]
>

/** filters that outcome must be a string */
export type TextFiltersValues<TTextFiltersValuesKey extends string> = Record<
  TTextFiltersValuesKey,
  string
>

/** filters that outcome must be a single select */
export type SingleSelectFiltersValues<TSingleSelectFiltersValuesKey extends string> = Record<
  TSingleSelectFiltersValuesKey,
  number
>

/** filters that outcome must be a logic object */
export type LogicOperatorFiltersValues<TLogicOperatorFiltersValuesKey extends string> = Record<
  TLogicOperatorFiltersValuesKey,
  LogicOperatorInputValueDto
>

export type SearchSelectFiltersOptions<TSearchSelectFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<
    TSearchSelectFiltersValuesKey,
    {
      value: number
      label: string
    }[]
  >

export type SearchSelectEnumFiltersOptions<TSearchSelectEnumFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<
    TSearchSelectEnumFiltersValuesKey,
    {
      value: string
      label: string
    }[]
  >
export type SearchLogEntryDataQualitySelectFiltersOptions<
  TSearchSelectFiltersValuesKey extends string,
> = SearchSelectFiltersValuesMap<
  TSearchSelectFiltersValuesKey,
  {
    value: LogEntryDataQuality
    label: string
  }[]
>
export type SearchSelectFiltersOptionsLoading<TSearchSelectFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<TSearchSelectFiltersValuesKey, boolean>
export type SearchSelectFiltersOptionsOnSearch<TSearchSelectFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<TSearchSelectFiltersValuesKey, (value: string) => void>

type SearchSelectFiltersValuesMap<TSearchSelectFiltersValuesKey extends string, T> = Partial<
  Record<keyof SearchSelectFiltersValues<TSearchSelectFiltersValuesKey>, T>
>

export enum FomInputType {
  SEARCH_SELECT = 'SEARCH_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  DATE_PICKER = 'DATE_PICKER',
  DATE_PICKER_YEAR = 'DATE_PICKER_YEAR',
  TEXT = 'TEXT',
  LOGIC_OPERATOR = 'LOGIC_OPERATOR',
  RANGE_DATE_PICKER = 'RANGE_DATE_PICKER',
}

export type FormInput<
  TSearchSelectFiltersValuesKey extends string,
  TSearchSelectStringFiltersValuesKey extends string,
  TSearchLogEntryDataQualitySelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TDateRangePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
  TSingleSelectFiltersValuesKey extends string,
> = { label: string; icon?: JSX.Element } & (
  | {
      type: FomInputType.SEARCH_SELECT
      key: keyof SearchSelectFiltersValues<TSearchSelectFiltersValuesKey>
    }
  | {
      type: FomInputType.SEARCH_SELECT
      key: keyof SearchSelectFiltersValues<TSearchLogEntryDataQualitySelectFiltersValuesKey>
    }
  | {
      type: FomInputType.SEARCH_SELECT
      key: keyof SearchSelectStringFiltersValue<TSearchSelectStringFiltersValuesKey>
    }
  | {
      type: FomInputType.DATE_PICKER
      key: keyof DatePickerFiltersValues<TDatePickerFiltersValuesKey>
    }
  | {
      type: FomInputType.DATE_PICKER_YEAR
      key: keyof DatePickerFiltersValues<TDatePickerFiltersValuesKey>
    }
  | {
      type: FomInputType.RANGE_DATE_PICKER
      key: keyof DateRangePickerFiltersValues<TDateRangePickerFiltersValuesKey>
    }
  | {
      type: FomInputType.TEXT
      key: keyof TextFiltersValues<TTextFiltersValuesKey>
      placeholder: string
    }
  | {
      type: FomInputType.LOGIC_OPERATOR
      key: keyof LogicOperatorFiltersValues<TLogicOperatorFiltersValuesKey>
      options: LogicOperatorOption[]
      inputProps?: InputProps
    }
  | {
      type: FomInputType.SINGLE_SELECT
      key: keyof SingleSelectFiltersValues<TSingleSelectFiltersValuesKey>
    }
)
