import { FactorRequestTableRowDto, PaginatedQueryResult, PaginationDto } from '@cozero/dtos'
import { FactorRequestDetailsView } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { RtkEndpointBuilderArg, providesList } from '@/utils/redux'

import {
  FIND_FACTOR_REQUEST_BY_ID,
  GET_MANY_INCOMING_REQUESTS,
  GET_MANY_OUTGOING_REQUESTS,
  TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS,
  TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
  TAG_ONE_FACTOR_REQUEST,
} from './constants'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const buildFactorRequestsMainEndpoints = (builder: RtkEndpointBuilderArg) => ({
  [FIND_FACTOR_REQUEST_BY_ID]: builder.query<FactorRequestDetailsView, string>({
    query: (id) => ({
      url: logApiGatewayClient.factorRequests.GET_ONE.replace(':id', id),
      method: 'GET',
    }),
    providesTags: (result) => [{ type: TAG_ONE_FACTOR_REQUEST, id: result?.id }],
  }),
  [GET_MANY_OUTGOING_REQUESTS]: builder.query<
    PaginatedQueryResult<FactorRequestTableRowDto>,
    PaginationDto
  >({
    query: (params) => ({
      url: logApiGatewayClient.factorRequests.GET_MANY_OUTGOING,
      method: 'GET',
      params,
    }),
    providesTags: (result) => providesList(result?.data, TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS),
  }),
  [GET_MANY_INCOMING_REQUESTS]: builder.query<
    PaginatedQueryResult<FactorRequestTableRowDto>,
    PaginationDto
  >({
    query: (params) => ({
      url: logApiGatewayClient.factorRequests.GET_MANY_INCOMING,
      method: 'GET',
      params,
    }),
    providesTags: (result) => providesList(result?.data, TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS),
  }),
})
