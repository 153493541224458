import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import GenericStepSelector from '@/pages/GenericLifecycleSteps/GenericStepSelector'
import SelectSteps from '@/pages/GenericLifecycleSteps/SelectSteps'
import { LifecycleStepsID, LifecycleStepsKey } from '@/pages/GenericLifecycleSteps/hooks/steps'
import { CbamTemplatesPage } from '@/pages/Share/SupplierEngagement/Cbam/CbamTemplatesPage'
import UpsertSupplierProductPage from '@/pages/Share/SupplierEngagement/NewProduct'
import SupplierProductConfiguration from '@/pages/Share/SupplierEngagement/NewProduct/SupplierProductConfiguration'

import LifecycleProvider from '@/contexts/lifecycle'
import LogProvider from '@/contexts/log'

import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <LifecycleProvider>
      <PrivateRoute>{children}</PrivateRoute>
    </LifecycleProvider>
  </LogProvider>
)

const getSupplierEngagementRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.share.supplierEngagement.base,
    element: (
      <Wrapper>
        <UpsertSupplierProductPage />
      </Wrapper>
    ),
    breadcrumb: t('layout.products'),
    children: [
      {
        path: routes.share.supplierEngagement.stepsCreate,
        element: <SelectSteps />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.configurationCreate,
        element: <SupplierProductConfiguration />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.rawMaterialsAcquisitionCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['raw-materials-acquisition']}
            step={LifecycleStepsKey.RAW_MATERIALS_ACQUISITION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.productionCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.production}
            step={LifecycleStepsKey.PRODUCTION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.distributionAndStorageCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['distribution-and-storage']}
            step={LifecycleStepsKey.DISTRIBUTION_AND_STORAGE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.packagingCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.packaging}
            step={LifecycleStepsKey.PACKAGING}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.stepsEdit,
        element: <SelectSteps edit />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.configurationEdit,
        element: <SupplierProductConfiguration />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.rawMaterialsAcquisitionEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['raw-materials-acquisition']}
            step={LifecycleStepsKey.RAW_MATERIALS_ACQUISITION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.productionEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.production}
            step={LifecycleStepsKey.PRODUCTION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.distributionAndStorageEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['distribution-and-storage']}
            step={LifecycleStepsKey.DISTRIBUTION_AND_STORAGE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.share.supplierEngagement.packagingEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.packaging}
            step={LifecycleStepsKey.PACKAGING}
          />
        ),
        breadcrumb: t('layout.products'),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
  {
    path: routes.share.supplierEngagementCbam.uploadTemplate,
    element: <CbamTemplatesPage />,
  },
]

export default getSupplierEngagementRoutes
