import React, { ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Result, Row } from 'antd/es'

import { routes } from '@cozero/utils'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import Button from '../atoms/Button'
import Text from '../atoms/Text'
import Title from '../atoms/Title'

const PageNotFound = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Row align="middle" justify="center">
      <Col>
        <Result
          status="404"
          title={<Title size="sm">404</Title>}
          subTitle={<Text size="lg">{t('general-errors.404')}</Text>}
          extra={[
            <Button
              key="back"
              action="Go back"
              category={AnalyticsCategories.LOGS}
              type="ghost"
              onClick={() => navigate(-1)}
            >
              {t('actions.back')}
            </Button>,
            <Button
              key="back"
              action="Go to dashboard"
              category={AnalyticsCategories.LOGS}
              type="primary"
              onClick={() => navigate(routes.dashboardHome.home)}
            >
              {t('actions.go-home')}
            </Button>,
          ]}
        />
      </Col>
    </Row>
  )
}

export default memo(PageNotFound)
