import React, { ReactElement, memo } from 'react'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'

const EditLogCrumb = (): ReactElement => {
  const { id } = useParams()
  return <>{`${t('log.log-entries-overview.table-columns.log-id')} ${id}`}</>
}

export default memo(EditLogCrumb)
