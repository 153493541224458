/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { uniqBy } from 'lodash-es'

import FilesModal from '@/organisms/FilesModal'

import { useAppDispatch } from '@/redux'
import api from '@/redux/api'
import { TAG_PAGINATED_LOG_ENTRIES_EMISSIONS } from '@/redux/logEntries/tags'

import { RowSelectionResult } from '../../../hooks/useRowsSelection'
import { ValidateLogEntriesModal } from '../ValidateLogEntriesModal/ValidateLogEntriesModal'

type LogEntryAttachFilesModalProps = {
  open: boolean
  onClose: () => void
  selectedRows: RowSelectionResult['selectedRows']
}

export const LogEntryAttachFilesModal = ({
  selectedRows = [],
  open,
  onClose,
}: LogEntryAttachFilesModalProps): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [displayFileModal, setDisplayFileModal] = React.useState(false)
  const [editableLogEntriesIds, setEditableLogEntriesIds] = React.useState<
    RowSelectionResult['selectedLogEntriesIds'] | undefined
  >(undefined)

  const logIds = React.useMemo(() => {
    const editableRows = editableLogEntriesIds
      ? selectedRows.filter(({ logEntry }) => editableLogEntriesIds.includes(logEntry.id))
      : selectedRows

    return uniqBy(editableRows, 'log.id').map((row) => row.log.id)
  }, [editableLogEntriesIds, selectedRows])

  const selectedLogEntriesIds = React.useMemo(
    () => uniqBy(selectedRows, 'logEntry.id').map((row) => row.logEntry.id),
    [selectedRows],
  )

  const onSuccess = React.useCallback(() => {
    dispatch(api.util.invalidateTags([{ type: TAG_PAGINATED_LOG_ENTRIES_EMISSIONS }]))
  }, [dispatch])

  React.useEffect(() => {
    if (!open) {
      setDisplayFileModal(false)
      setEditableLogEntriesIds(undefined)
    }
  }, [open])

  if (displayFileModal) {
    return (
      <FilesModal
        onSuccess={onSuccess}
        logIds={logIds}
        isVisible={open}
        onClose={onClose}
        isReadOnly={false}
        loadExistingFiles={false}
        closeAfterUpload
        successMessage={t('log.log-entries-overview.actions.attach-files.success-message')}
        errorMessage={t('log.log-entries-overview.actions.attach-files.error-message')}
      />
    )
  }

  return (
    <ValidateLogEntriesModal
      selectedLogEntriesIds={selectedLogEntriesIds}
      open={open}
      onCancel={onClose}
      onValid={() => setDisplayFileModal(true)}
      onPartialSubmit={(logEntriesIds) => {
        setEditableLogEntriesIds(logEntriesIds)
        setDisplayFileModal(true)
      }}
      actionNotPossibleText={t(
        'log.log-entries-overview.actions.attach-files.alert-unable-to-attach',
      )}
      actionPartiallyNotPossibleInfoText={t(
        'log.log-entries-overview.actions.attach-files.alert-with-conflict',
      )}
      actionPartiallyNotPossibleKey="log.log-entries-overview.actions.attach-files.confirmation-text-with-conflict"
      confirmText={t('log.log-entries-overview.actions.attach-files.ok-text')}
    />
  )
}
